import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { storageService } from '../../../services/storage.service';

interface IUserResponseById{
    general: {
        first_name: string;
        last_name: string;
        country: string;
        email: string;
        registration_date: string;
        last_signup: string | null;
        registration_ip: string | null;
        affiliate: boolean;
        dob: string | null;
        phone: string;
        last_login_ip: string;
        balance_eur: number;
    },
    account: {
        email_confirmed: boolean;
        id_confirmed: boolean;
        selfie_confirmed: boolean;
        volume: number;
        trading_fee: number;
        spread_fee: number;
    },
    active: boolean;
}

export interface IBalanceResponse {
    first_name: string;
    last_name: string;
    email: string;
    wallets: {
        total: number;
        change24h: number;
        balances: {
            address: string;
            currency: string;
            network: string;
            balance: number;
            balance_eur: number;
            balance_btc: number;
            balance_usdt: number;
            balance_eth: number;
            change24h: number | null;
            balance_eur24h_ago: number | null
        }[]
    }
}

export interface IChangePassword {
    user_id: number;
    password: string; 
    confirm_password: string;
}

interface IEditUser{
    email?: string,
    first_name?: string,
    last_name?: string,
    otp_required?: boolean
}

export interface IIdEditUser {
    user_id: number;
    body?: IEditUser,
    confirmation?: {
        email_confirmed?: boolean,
        id_confirmed?: boolean,
        selfie_confirmed?: boolean,
    }
}

export const userApi = createApi({
    reducerPath: 'userApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_BASE_URL}/api/admin/user/`,
        prepareHeaders: (headers) => {
            headers.set('Authorization', `Bearer ${storageService.getAccessToken}`)
            return headers
        }
    }),
    endpoints: (builder) => ({
        getUser: builder.query<IUserResponseById, string>({
            query: (params) => ({
                url: `${params}`,
                method: 'GET'
            })
        }),
        getBalance: builder.query<IBalanceResponse, string>({
            query: (params) => ({
                url: `${params}/balance`,
                method: 'GET'
            })
        }),
        changePassword: builder.mutation<boolean, IChangePassword>({
            query: ({user_id, password, confirm_password}) => ({
                url: `${user_id}/change_password`,
                method: 'POST',
                body: {
                    user_id, 
                    password, 
                    confirm_password
                }
            })
        }),
        editUser: builder.mutation<boolean, IIdEditUser>({
            query: ({user_id, body, confirmation}) => ({
                url: `${user_id}`,
                method: 'POST',
                body: {
                    ...body,
                    ...confirmation
                }
            })
        }) 
    })
})


export const {useGetUserQuery, useGetBalanceQuery, useChangePasswordMutation, useEditUserMutation} = userApi