import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { storageService } from '../../../services/storage.service';

export type AuthRequest = {
  email: string,
  password: string
}

type AuthResponse = {
  access_token: string,
  refresh_token: string,
  token_type: string
}

export const profileApi = createApi({
  reducerPath: 'profileApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_BASE_URL}/api/v1/`,
    prepareHeaders: (headers) => {
      headers.set('Authorization', `Bearer ${storageService.getAccessToken}`)
      return headers
    }
  }),
  endpoints: (builder) => ({
    auth: builder.mutation<AuthResponse, {email: string, password: string}>({
      query: ({email, password}) => ({
        url: 'login',
        method: 'POST',
        body: {
          email,
          password
        }
      })
    }),
    logout: builder.mutation({
      query: () => ({
        url: 'logout',
        method: 'GET',
        headers: {
          'x-refresh-token': storageService.getRefreshToken
        }
      })
    })
  })
})

export const {
  useAuthMutation,
  useLogoutMutation
} = profileApi
