import React, { useState, useMemo } from "react";
import { 
    Box, 
    Button, 
    Typography, 
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    InputAdornment,
} from "@mui/material";
import { StyledInput, StyledTextField } from "./Users/styled.users";
import { HiOutlineSearch } from "react-icons/hi";
import { SVGs } from "../assets/icons/images";
import { useLocation, useNavigate } from "react-router-dom";
import { IBalanceResponse, IChangePassword } from "../store/slices/user/user.api";

const ClientWallet = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const walletState = location.state as IBalanceResponse;

    const total = useMemo(() => {
        const totalEuro = walletState.wallets.balances.reduce((prev, cur) => prev + cur.balance_eur, 0)
        const totalBtc = walletState.wallets.balances.reduce((prev, cur) => prev + cur.balance_btc, 0)
        return {
            totalBtc, 
            totalEuro
        }
    }, [walletState]);

    return <Box width={"100%"}>
        
        <Box width={"100%"} bgcolor={"#fff"}>
            <Box p={"22px 33px"} width={"139px"}>
                <Box  
                    display={"flex"} 
                    height={"35px"}
                    alignItems={"center"}
                    border={"1.5px solid #00B7F1"}
                    justifyContent={"center"}
                    gap={"10px"}   
                    onClick={() => navigate(-1)}
                >
                    <img src={SVGs.backIcon}/>
                    <Typography
                        fontFamily={"Poppins"}
                        fontSize={"16px"}
                        color={"#00b7f1"}
                    >
                        Back
                    </Typography>
                </Box>
            </Box>
            <Box display={"flex"}
            gap={"50px"}
            p={"22px 33px"}
        >
            <Box>
                <img src={SVGs.user} style={{marginTop: "10px"}} alt=""/>
            </Box>
            <Box width={"80%"}>
                <Box>
                    <Typography
                        fontFamily={"Poppins"}
                        fontWeight={"bold"}
                        fontSize={"25px"}
                        color={"#111928"}
                    >
                        Client wallet
                    </Typography>
                    <Typography
                        fontFamily={"Poppins"}
                        fontWeight={"bold"}
                        fontSize={"15px"}
                        color={"#111928"}
                    >
                        {walletState.first_name + ' ' + walletState.last_name}
                    </Typography>
                    <Typography 
                        color={"#4B5563"}
                        fontFamily={"Poppins"}
                    >
                        {walletState.email}
                    </Typography>
                </Box>
                <Box 
                    display={"flex"} 
                    gap={"70px"}
                    mt={"30px"}
                >
                    <Box 
                        width={"40%"}
                    >
                        <Typography
                            fontFamily={"Poppins"}
                            fontWeight={"bold"}
                            fontSize={"16px"}
                            color={"#111928"}
                            mb={"25px"}
                        >
                            Total value (BTC)
                        </Typography>
                        <StyledInput
                            value={total.totalBtc}
                            disabled
                        />
                    </Box>
                    <Box 
                        width={"40%"}
                    >
                        <Typography
                            fontFamily={"Poppins"}
                            fontWeight={"bold"}
                            fontSize={"16px"}
                            color={"#111928"}
                            mb={"25px"}
                        >
                            Total value (€)
                        </Typography>
                        <StyledInput
                            value={total.totalEuro}
                            disabled
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
        </Box>
        <Box bgcolor={"#fff"}>
            <Box p={"50px 64px 36px"}>
                <Typography
                    fontFamily={"Poppins"}
                    fontWeight={"bold"}
                    fontSize={"24px"}
                >
                    Wallet
                </Typography>
            </Box>
            <Box overflow={'scroll'} maxHeight={"500px"}>
                <Table>
                    <TableHead
                        style={{
                            position: 'sticky',
                            top: 0
                        }}
                    >
                        <TableRow sx={{backgroundColor: '#80848E', borderRadius: '10px'}}>
                            <TableCell>
                                <Typography
                                    fontFamily={"Poppins"}
                                    fontWeight={600}
                                    fontSize={"14px"}
                                    color={"#fff"}
                                >
                                    Currency
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography
                                    fontFamily={"Poppins"}
                                    fontWeight={600}
                                    fontSize={"14px"}
                                    color={"#fff"}
                                >
                                    Amount
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography
                                    fontFamily={"Poppins"}
                                    fontWeight={600}
                                    fontSize={"14px"}
                                    color={"#fff"}
                                >
                                    Value (BTC)
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography
                                    fontFamily={"Poppins"}
                                    fontWeight={600}
                                    fontSize={"14px"}
                                    color={"#fff"}
                                >
                                    Value (€)
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            walletState.wallets.balances.map((wl, index) => (
                                <TableRow key={index}>
                                    <TableCell>
                                        <Typography 
                                            fontFamily={"Poppins"}
                                            fontSize={"14px"}
                                            fontWeight={"bold"}
                                        >
                                            {wl.currency}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography 
                                            fontFamily={"Poppins"}
                                            fontSize={"14px"}
                                            fontWeight={600}    
                                        >
                                            {wl.balance}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography 
                                            fontFamily={"Poppins"}
                                            fontSize={"14px"}
                                            fontWeight={600}    
                                        >
                                            {wl.balance_btc}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography 
                                            fontFamily={"Poppins"}
                                            fontSize={"14px"}
                                            fontWeight={600}    
                                        >
                                            € {wl.balance_eur}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </Box>
        </Box>
    </Box> 
}

export default ClientWallet;