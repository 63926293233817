import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { storageService } from '../../../services/storage.service'

export interface IRevenueResponse {
    market_income: number;
    market_income_eur: number;
    market_base_fee: number;
    date: string; 
}

export interface IDepositResponse {
    percent: number;
    days: IDay[]
}

export interface IDay {
    sum: number;
    date: string;
}

export const revenueApi = createApi({
    reducerPath: 'revenueApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_BASE_URL}/api/admin`,
        prepareHeaders: (headers) => {
            headers.set('Authorization', `Bearer ${storageService.getAccessToken}`)
            return headers
        }
    }),
    endpoints: (builder) => ({
        getRevenue: builder.query<IRevenueResponse[], string>({
            query: (period) => ({
                url: `/revenue?period=${period}`,
                method: 'GET'
            })
        }),
        getCommission: builder.query<IDepositResponse, unknown>({
            query: () => ({
                url: '/commission/deposit',
                method: 'GET'
            })
        }),
    })
})

export const {useGetRevenueQuery, useGetCommissionQuery} = revenueApi;