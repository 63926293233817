import React, {useEffect} from "react";
import {
    Box,
    Typography,
    Popover,
    List,
    ListItem,
    ListItemButton,
    ListItemText
} from "@mui/material";
import { useState } from "react";
import bitLogo from "../assets/icons/bitocolLogo.svg";
import { useLogoutMutation } from "../store/slices/profile/profile.api";

enum ProfileMenuActions {
    LogOut
}


const Header = () => {
    const [logout, {data: loggedOut}] = useLogoutMutation()
    const [anchor, setAnchor] = useState<HTMLAnchorElement | null>(null)

    const openProfileMenu = (e: React.MouseEvent<HTMLAnchorElement>) => {
        setAnchor(e.currentTarget)
    }

    const closeProfilemenu = () => {
        setAnchor(null)
    }

    const handleProfilemenu = (type: ProfileMenuActions) => {
        closeProfilemenu()

        switch (type) {
            case ProfileMenuActions.LogOut:
                console.log('logout')
                logout('logout')
        }
    }

    const profileMenuOpen = Boolean(anchor)
    const profileMenuId = profileMenuOpen ? 'profile_menu' : undefined

    useEffect(() => {
        if(loggedOut === null){
            window.sessionStorage.clear();
        }
    })

    return <Box
            display={"flex"}
            justifyContent={"space-between"}
            bgcolor={'#011031'}
            p={"25px 35px"}
        >
        
        <Box 
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            gap={"10px"}
        >
            <Box>
                <img src={bitLogo}/>
            </Box>
            <Box 
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                fontSize={"22px"}
                color={"#FFFFFF"}
                paddingBottom={"5px"}
            >
                BITOCOL
            </Box>
        </Box>


        <Box
            width={"39px"}
            height={"39px"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            borderRadius={"50%"}
            border={"1.5px solid #00B7F1"}
            color={"#00B7F1"}
            fontSize={"16px"}
            fontWeight={500}
            sx={{
                cursor: 'pointer'
            }}
            onClick={(e) => openProfileMenu(e as any)}>
            <Typography>
                TV
            </Typography>
        </Box>
        <Popover
            id={profileMenuId}
            open={profileMenuOpen}
            anchorEl={anchor}
            onClose={closeProfilemenu}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
            }}>
                <List>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => handleProfilemenu(ProfileMenuActions.LogOut)}>
                            <ListItemText>Log out</ListItemText>
                        </ListItemButton>
                    </ListItem>
                </List>
        </Popover>

    </Box>
}

export default Header;