import { Route, Routes } from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import Users from "../pages/Users";
import Affiliates from "../pages/Affiliates/Affiliates";
import Commisions from "../pages/Commisions";
import Currency from "../pages/Currency";
import AuthPage from "../pages/Auth";
import PrimaryLayout from "../components/Layout/PrimaryLayout";
import AuthLayout from "../components/Layout/AuthLayout";
import User from "../pages/Users/User";
import UserAffiliate from "../pages/Affiliates/UserAffiliate";
import ClientWallet from "../pages/ClientWallet";
import News from "../pages/News/News";
import CreateNews from "../pages/News/NewsCreate";

export const AppRoutes = () => {
	return (
		<Routes>
			<Route path='/admin'>
				<Route element={<PrimaryLayout />}>
					<Route index element={<Dashboard />} />
					<Route path='users' element={<Users />} />
					<Route path='users/:id' element={<User />} />
					<Route path='affiliates' element={<Affiliates />} />
					<Route path='affiliates/:id' element={<UserAffiliate />} />
					<Route path='commissions' element={<Commisions />} />
					<Route path='currencies' element={<Currency />} />
					<Route path='users/:id/client-wallet' element={<ClientWallet />} />
					<Route path='news' element={<News />} />
					<Route path='news/create' element={<CreateNews />} />
				</Route>
			</Route>
			<Route path='/admin/auth'>
				<Route element={<AuthLayout />}>
					<Route index element={<AuthPage />} />
				</Route>
			</Route>
		</Routes>
	);
};
