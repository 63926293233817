import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { storageService } from '../../../services/storage.service'

interface IAffsResponse {
    count_referred: number;
    count_affiliate: number;
}

interface IRefsResponse {
    data: {
        id: number;
        email: string;
        name: string;
        income_30d: number | null;
        is_active: boolean;
        created_at: string;
    }[],
    previous_page: number | null;
    next_page: number | null;
    total: number;
    pages: number;
}

interface IAffReq {
    id_: number,
    q: string | null;
    page?: number,
    page_size?: number,
}

interface IAffUserResponse {
    first_name: string;
    last_name: string;
    affiliate_id: number;
    affiliate_code: string;
    commission_percent: number;
    total_earned: number | null;
    count_referred: number;
}

export interface IEditAffId {
    id: number,
    first_name?: string,
    last_name?: string,
    email?: string,
    commission?: number, 
}

export const affApi = createApi({
    reducerPath: 'affApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_BASE_URL}/api/admin`,
        prepareHeaders: (headers) => {
            headers.set('Authorization', `Bearer ${storageService.getAccessToken}`)
            return headers
        }
    }),
    endpoints: (builder) => ({
        getAffs: builder.query<IAffsResponse, unknown>({
            query: () => ({
                url: '/affiliates',
                method: 'GET'
            })
        }),
        getReferrals: builder.query<IRefsResponse, IAffReq>({
            query: ({id_, ...params}) => ({
                url: `/affiliate/${id_}/referrals?q=${params.q}`,
                method: 'GET'
            })
        }),
        getAffOfUser: builder.query<IAffUserResponse, string>({
            query: (params) => ({
                url: `/affiliate/${params}`,
                method: 'GET'
            })
        }),
        editAffId: builder.mutation<boolean, IEditAffId>({
            query: ({id, ...body}) => ({
                url: `/affiliate/${id}`,
                method: 'POST',
                body: {...body}
            })
        })
    })
})

export const {
    useGetAffsQuery,
    useLazyGetReferralsQuery, 
    useGetReferralsQuery, 
    useGetAffOfUserQuery,
    useEditAffIdMutation
} = affApi;