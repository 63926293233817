import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import {usersSlice} from "./slices/users/users.slice";
import { profileSlice } from './slices/profile/profile.slice';
import { profileApi } from './slices/profile/profile.api';
import { usersApi } from './slices/users/users.api';
import { countriesApi } from './slices/countries/countries.api';
import { dashboardApi } from './slices/dashboard/dashboard.api';
import { userApi } from './slices/user/user.api';
import { affApi } from './slices/affliates/affiliates.api';
import { revenueApi } from './slices/commissions/revenue.api';
import { currencyApi } from './slices/currency/currency,api';
import { newsApi } from './slices/news/news.api';

export const store = configureStore({
    reducer: {
        usersSlice: usersSlice.reducer,
        profileSlice: profileSlice.reducer,
        [profileApi.reducerPath]: profileApi.reducer,
        [usersApi.reducerPath]: usersApi.reducer,
        [countriesApi.reducerPath]: countriesApi.reducer,
        [dashboardApi.reducerPath]: dashboardApi.reducer,
        [userApi.reducerPath]: userApi.reducer,
        [affApi.reducerPath]: affApi.reducer,
        [revenueApi.reducerPath]: revenueApi.reducer,
        [currencyApi.reducerPath]: currencyApi.reducer,
        [newsApi.reducerPath]: newsApi.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([
        profileApi.middleware,
        usersApi.middleware,
        countriesApi.middleware,
        dashboardApi.middleware,
        userApi.middleware,
        affApi.middleware,
        revenueApi.middleware,
        currencyApi.middleware,
        newsApi.middleware
    ])
})

setupListeners(store.dispatch)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch