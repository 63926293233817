import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import { SVGs } from "../assets/icons/images";
import NewspaperOutlinedIcon from "@mui/icons-material/NewspaperOutlined";
import "../styles/nav.scss";

const NavBar = () => {
	const [navLink, setNavlink] = useState<
		"Dashboard" | "Users" | "Currency" | "Affl" | "Commission" | "News"
	>("Dashboard");
	return (
		<Box
			minWidth={"198px"}
			minHeight={"85%"}
			display={"flex"}
			flexDirection={"column"}
			gap={"35px"}
			borderRadius={"10px"}
			p={"40px 25px"}
			sx={{ bgcolor: "#fff" }}>
			<NavLink
				end
				to='/admin'
				className={(navData) => (navData.isActive ? "active" : "")}
				onClick={() => setNavlink("Dashboard")}>
				<Box display={"flex"} gap={"20px"} height={"100%"} alignItems={"center"}>
					<img src={navLink === "Dashboard" ? SVGs.lightDash : SVGs.dashboard} alt='' />
					<Typography fontFamily={"Inter"}>Dashboard</Typography>
				</Box>
			</NavLink>
			<NavLink
				to='/admin/users'
				className={(navData) => (navData.isActive ? "active" : "")}
				onClick={() => setNavlink("Users")}>
				<Box display={"flex"} gap={"20px"} height={"100%"} alignItems={"center"}>
					<img src={navLink === "Users" ? SVGs.lightUsers : SVGs.users} alt='' />
					<Typography fontFamily={"Inter"}>Users</Typography>
				</Box>
			</NavLink>
			<NavLink
				to='/admin/affiliates'
				className={(navData) => (navData.isActive ? "active" : "")}
				onClick={() => setNavlink("Affl")}>
				<Box display={"flex"} gap={"20px"} height={"100%"} alignItems={"center"}>
					<img src={navLink === "Affl" ? SVGs.lightAffl : SVGs.affl} alt='' />
					<Typography fontFamily={"Inter"}>Affiliates</Typography>
				</Box>
			</NavLink>
			<NavLink
				to='/admin/commissions'
				className={(navData) => (navData.isActive ? "active" : "")}
				onClick={() => setNavlink("Commission")}>
				<Box display={"flex"} gap={"20px"} height={"100%"} alignItems={"center"}>
					<img src={navLink === "Commission" ? SVGs.lightCommissions : SVGs.commision} alt='' />
					<Typography fontFamily={"Inter"}>Commissions</Typography>
				</Box>
			</NavLink>
			<NavLink
				to='/admin/currencies'
				className={(navData) => (navData.isActive ? "active" : "")}
				onClick={() => setNavlink("Currency")}>
				<Box display={"flex"} gap={"20px"} height={"100%"} alignItems={"center"}>
					<img src={navLink === "Currency" ? SVGs.lightCurrency : SVGs.currency} alt='' />
					<Typography fontFamily={"Inter"}>Currencies</Typography>
				</Box>
			</NavLink>
			<NavLink
				to='/admin/news'
				className={(navData) => (navData.isActive ? "active" : "")}
				onClick={() => setNavlink("News")}>
				<Box display={"flex"} gap={"20px"} height={"100%"} alignItems={"center"}>
					<NewspaperOutlinedIcon />
					<Typography fontFamily={"Inter"}>News</Typography>
				</Box>
			</NavLink>
		</Box>
	);
};

export default NavBar;
