import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { IRecentResponse, ISummaryResponse, IMarketResponse, ICountrySignUp } from './dashboard.state'
import { storageService } from '../../../services/storage.service';


export const dashboardApi = createApi({
    reducerPath: 'dashboardApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_BASE_URL}/api/admin/dashboard`,
        prepareHeaders: (headers) => {
            headers.set('Authorization', `Bearer ${storageService.getAccessToken}`)
            return headers
        }
    }),
    endpoints: (builder) => ({
        getRecent: builder.query<IRecentResponse, string>({
            query: (period) => ({
                url: `/recent?period=${period}`,
                method: 'GET'
            })
        }),
        getSummary: builder.query<ISummaryResponse, any>({
            query: () => ({
                url: '/summary',
                method: 'GET'
            })
        }),
        getMarkets: builder.query<IMarketResponse, any>({
            query: () => ({
                url: '/markets',
                method: 'GET'
            })
        }),
        getCountriesSignUp: builder.query<ICountrySignUp[], any>({
            query: () => ({
                url: '/top_signup',
                method: 'GET'
            })
        }),
    })
})

export const {
    useGetRecentQuery,
    useGetMarketsQuery,
    useGetSummaryQuery,
    useGetCountriesSignUpQuery
} = dashboardApi