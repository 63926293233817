import React, { useMemo, useState, useEffect } from "react";
import {
	Box,
	Button,
	Typography,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Modal,
	ButtonGroup,
	Input,
	TextareaAutosize,
} from "@mui/material";
import {
	useEditNewsMutation,
	useGetNewsQuery,
	useLazyGetNewsQuery,
	useDeleteNewsMutation,
	INews,
} from "../../store/slices/news/news.api";
import { useNavigate } from "react-router-dom";

const News = () => {
	const navigate = useNavigate();
	const [lazyLoadNews, { data: news }] = useLazyGetNewsQuery();

	const [toDeleteNews, setDeleteNews] = useState(false);
	const [objectToDelete, setObjectToDelete] = useState<INews>();
	const [objectToEdit, setObjectToEdit] = useState<INews>();

	const [delNews, { data: isDeleted }] = useDeleteNewsMutation();
	const [editNews, { data: edited }] = useEditNewsMutation();

	const deleteNews = (id: number) => {
		delNews(id);
	};

	useEffect(() => {
		if (isDeleted) {
			setObjectToDelete(undefined);
		}
		if (edited) {
			setObjectToEdit(undefined);
		}
		lazyLoadNews(null);
	}, [isDeleted, edited]);

	useEffect(() => {
		if (objectToEdit) {
			const date = new Date(objectToEdit.show_for);
			const str = `${date.getDate()}-${date.getMonth()}-${date.getFullYear()}T${date.getHours()}:${date.getMinutes()}`;
		}
	}, [objectToEdit]);

	return (
		<Box width={"100%"}>
			<Modal open={objectToEdit !== undefined}>
				<Box
					display={"flex"}
					flexDirection={"column"}
					alignItems={"center"}
					justifyContent={"center"}
					gap={"25px"}
					borderRadius={"10px"}
					fontWeight={600}
					bgcolor={"#fff"}
					maxWidth={"650px"}
					minHeight={"250px"}
					maxHeight={"350px"}
					margin={"170px auto"}
					p={"20px"}>
					<Input
						fullWidth
						type='text'
						defaultValue={objectToEdit?.title}
						onChange={(e) =>
							setObjectToEdit({
								...objectToEdit!,
								title: e.target.value,
							})
						}
					/>
					<TextareaAutosize
						minRows={4}
						minLength={4}
						defaultValue={objectToEdit?.text}
						onChange={(e) =>
							setObjectToEdit({
								...objectToEdit!,
								text: e.target.value,
							})
						}
					/>
					<Input
						fullWidth
						type='datetime-local'
						defaultValue={objectToEdit?.show_for}
						onChange={(e) => {
							const isoDate = new Date(e.target.value);
							setObjectToEdit({
								...objectToEdit!,
								text: isoDate.toISOString(),
							});
						}}
					/>
					<Box display={"flex"} gap={"15px"} mt={"15px"}>
						<Button variant='outlined' onClick={() => setObjectToEdit(undefined)}>
							Close
						</Button>
						<Button
							variant='outlined'
							onClick={() =>
								editNews({
									news_id: objectToEdit!.id,
									show_for: objectToEdit!.show_for,
									title: objectToEdit!.title,
									text: objectToEdit!.text,
								})
							}>
							Save and Edit
						</Button>
					</Box>
				</Box>
			</Modal>
			<Modal open={objectToDelete !== undefined}>
				<Box
					borderRadius={"10px"}
					fontWeight={600}
					bgcolor={"#fff"}
					maxWidth={"650px"}
					minHeight={"250px"}
					margin={"170px auto"}
					display={"flex"}
					flexDirection={"column"}
					alignItems={"center"}
					justifyContent={"center"}>
					<Typography fontFamily={"Poppins"} fontSize={"16px"} color={"#000"}>
						You are going to delete news with title "{objectToDelete?.title}"
					</Typography>
					<Box display={"flex"} gap={"15px"} mt={"15px"}>
						<Button variant='outlined' onClick={() => setObjectToDelete(undefined)}>
							Close
						</Button>
						<Button variant='outlined' onClick={() => deleteNews(objectToDelete!.id)}>
							Delete
						</Button>
					</Box>
				</Box>
			</Modal>
			<Box display={"flex"} justifyContent={"flex-end"} mt={"20px"}>
				<Button
					style={{
						minWidth: "123px",
						minHeight: "40px",
						borderRadius: "10px",
						backgroundColor: "#0157FF",
						color: "#fff",
					}}
					onClick={() => navigate("/admin/news/create")}>
					Add news
				</Button>
			</Box>
			<Box borderRadius={"10px"} bgcolor={"#fff"} mt={"15px"} minHeight={"650px"}>
				<Table>
					<TableHead style={{ background: "#80848E" }}>
						<TableCell>
							<Typography fontFamily={"Poppins"} fontWeight={700} fontSize={"16px"} color={"#fff"}>
								№
							</Typography>
						</TableCell>
						<TableCell>
							<Typography fontFamily={"Poppins"} fontWeight={700} fontSize={"16px"} color={"#fff"}>
								Date of creation
							</Typography>
						</TableCell>
						<TableCell>
							<Typography fontFamily={"Poppins"} fontWeight={700} fontSize={"16px"} color={"#fff"}>
								Title
							</Typography>
						</TableCell>
						<TableCell>
							<Typography fontFamily={"Poppins"} fontWeight={700} fontSize={"16px"} color={"#fff"}>
								Show until
							</Typography>
						</TableCell>
						<TableCell>
							<Typography fontFamily={"Poppins"} fontWeight={700} fontSize={"16px"} color={"#fff"}>
								Actions
							</Typography>
						</TableCell>
						<TableCell></TableCell>
					</TableHead>
					<TableBody>
						{news?.data.map((item) => {
							const createdDate = new Date(item.created_at);
							const createDateStringified = `${createdDate.getDate()}.${
								createdDate.getMonth() + 1
							}. ${createdDate.getFullYear()}`;
							const showUntil = new Date(item.show_for);
							const showUntilStringified = `${showUntil.getDate()}.${
								showUntil.getMonth() + 1
							}. ${showUntil.getFullYear()} ${showUntil.getHours()}:${showUntil.getMinutes()}`;
							return (
								<TableRow key={item.id}>
									<TableCell>
										<Typography fontFamily={"Poppins"} fontWeight={500} fontSize={"16px"}>
											{item.id}
										</Typography>
									</TableCell>
									<TableCell>
										<Typography fontFamily={"Poppins"} fontWeight={500} fontSize={"16px"}>
											{createDateStringified}
										</Typography>
									</TableCell>
									<TableCell>
										<Typography fontFamily={"Poppins"} fontWeight={500} fontSize={"16px"}>
											{item.title}
										</Typography>
									</TableCell>
									<TableCell>
										<Typography fontFamily={"Poppins"} fontWeight={500} fontSize={"16px"}>
											{showUntilStringified}
										</Typography>
									</TableCell>
									<TableCell>
										<Button
											style={{
												color: "#0157FF",
												border: "1px solid #0157FF",
											}}
											onClick={() => {
												const date = new Date(item.show_for);
												const strDate = `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}T${date.getHours()}:${date.getMinutes()}`;
												setObjectToEdit({
													...item,
													show_for: strDate,
												});
											}}>
											Edit
										</Button>
									</TableCell>
									<TableCell>
										<Button
											style={{
												color: "#EB5757",
												border: "1px solid #EB5757",
											}}
											onClick={() => setObjectToDelete({ ...item })}>
											Delete
										</Button>
									</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</Box>
		</Box>
	);
};

export default News;
