import {ESaveMode, ILoginRes} from "../types/profile.types";
import jwt_decode from 'jwt-decode';

interface IJWT{
    exp: number;
    sub: string;
    type: string;
}

class StorageService {
    private accessToken: string
    private refreshToken: string
    public saveMode: ESaveMode = ESaveMode.sessionStorage

    constructor() {
        this.accessToken = sessionStorage.getItem('accessToken') as string || localStorage.getItem('accessToken') as string
        this.refreshToken = sessionStorage.getItem('refreshToken') as string || localStorage.getItem('refreshToken') as string
    }

    //Access Token
    public get getAccessToken() {
        return this.accessToken
    }

    //Refresh Token

    get getRefreshToken() {
        return this.refreshToken
    }

    get isHaveTokens(): boolean {
        return !!(localStorage.getItem('accessToken') || sessionStorage.getItem('accessToken'))
    }

    get isExpired(): boolean {
        let time = Date.now();
        const decoded: IJWT = jwt_decode(this.getAccessToken);
        return time >= decoded.exp * 1000; 
    }

    //Setter
    public async sendRefresh() {
        if(this.isExpired){
            const res = await fetch('https://api.bitocol.com/api/v1/refresh', {
                method: 'POST',
                headers: {
                    'x-refresh-token': this.getRefreshToken
                }
            })
            const data: ILoginRes = await res.json();
            this.setTokens = {
                access_token: data.access_token,
                token_type: data.token_type,
                refresh_token: data.refresh_token
            };
        }
    }
    public set setTokens({access_token, token_type, refresh_token}: ILoginRes) {
        this.accessToken = access_token
        this.refreshToken = refresh_token
        if (this.saveMode === ESaveMode.sessionStorage) {
            sessionStorage.setItem('accessToken', access_token)
            sessionStorage.setItem('refreshToken', refresh_token)
            sessionStorage.setItem('token_type', token_type)
        } else {
            localStorage.setItem('accessToken', access_token)
            localStorage.setItem('refreshToken', refresh_token)
            localStorage.setItem('token_type', token_type)
        }
    }

    //methods

    public removeTokens() {
        localStorage.removeItem('accessToken')
        localStorage.removeItem('refreshToken')
        sessionStorage.removeItem('accessToken')
        sessionStorage.removeItem('refreshToken')
    }

}

export const storageService = new StorageService()
