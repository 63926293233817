import React, { FC, useState, useCallback, useEffect } from "react";
import queryString from "query-string";
import {
	Box,
	Typography,
	InputAdornment,
	TableRow,
	TableBody,
	TableCell,
	MenuItem,
	Select,
	SelectChangeEvent,
	InputLabel,
	Button,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import {
	StyledFilterWrapper,
	StyledInput,
	StyledTextField,
	StyledSelect,
	StyledTable,
	StyledTableHead,
	StyledPersonalInfo,
	StyledButton,
} from "./styled.users";
import { HiOutlineSearch } from "react-icons/hi";
import { UsersSearchParams } from "../../types/users.types";
import { useGetUsersQuery } from "../../store/slices/users/users.api";
import { useGetCountriesQuery } from "../../store/slices/countries/countries.api";
import { useNavigate } from "react-router-dom";

const Users: FC = () => {
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useState<UsersSearchParams>({
		page: 1,
		name: null,
		email: null,
		country: null,
		active: null,
		balance: null,
		volume: null,
	});

	const formatNumber = (inp: string) => {
		return inp.replace(/[^0-9]/g, "");
	};

	const configureSearchParams = useCallback(
		() =>
			queryString.stringify(searchParams, {
				skipNull: true,
			}),
		[searchParams]
	);

	const [searchParamsQuery, setSearchParamsQuery] = useState<string>(configureSearchParams);

	const { isLoading: isCountriesLoading, data: countries } = useGetCountriesQuery(null);

	const { isLoading: isUsersLoading, data: users } = useGetUsersQuery(searchParamsQuery);

	const handleFilterSelect = (e: SelectChangeEvent<unknown>) => {
		setSearchParams((prevParams) => ({
			...prevParams,
			[e.target.name]: (e.target.value as string) === "all" ? null : (e.target.value as string),
		}));
	};

	const handleNumInput = (e: React.ChangeEvent<HTMLInputElement>) => {
		const formattedValue = formatNumber(e.target.value);
		setSearchParams((prevParams) => ({
			...prevParams,
			[e.target.name]: formattedValue.length ? formattedValue : null,
		}));
	};

	const handleStrInput = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchParams((prevParams) => ({
			...prevParams,
			[e.target.name]: e.target.value.length ? e.target.value : null,
		}));
	};

	useEffect(() => {
		setSearchParamsQuery(configureSearchParams());
	}, [searchParams, configureSearchParams]);

	return (
		<Box width={"100%"}>
			<StyledFilterWrapper>
				<Typography
					fontFamily={"Poppins"}
					fontWeight={600}
					fontSize={20}
					lineHeight={"30px"}
					marginBottom={"45px"}>
					Bitocol affiliate list
				</Typography>
				<Box display={"flex"}>
					<Box width={"330px"} marginRight={"70px"}>
						<Box>
							<InputLabel id='1'>Country</InputLabel>
							<Select
								labelId='1'
								fullWidth
								variant='standard'
								size='small'
								name='country'
								label='country'
								value={searchParams.country}
								disabled={isCountriesLoading}
								onChange={handleFilterSelect}
								style={{
									marginBottom: "50px",
								}}>
								{countries &&
									countries.length &&
									countries.map((country) => (
										<MenuItem key={country.code} value={country.name}>
											{country.name}
										</MenuItem>
									))}
							</Select>
						</Box>
						<StyledTextField
							fullWidth
							variant='standard'
							size='small'
							placeholder='Search name'
							name='name'
							onChange={handleStrInput}
							InputProps={{
								startAdornment: (
									<InputAdornment position='start'>
										<HiOutlineSearch />
									</InputAdornment>
								),
							}}
							style={{
								marginBottom: "50px",
							}}
						/>
						<StyledTextField
							fullWidth
							variant='standard'
							size='small'
							placeholder='Balance in Euro (>)'
							name='balance'
							value={searchParams.balance}
							onChange={handleNumInput}
							InputProps={{
								startAdornment: (
									<InputAdornment position='start'>
										<HiOutlineSearch />
									</InputAdornment>
								),
							}}
							style={{
								marginBottom: "50px",
							}}
						/>
					</Box>
					<Box width={"330px"}>
						<StyledTextField
							fullWidth
							variant='standard'
							size='small'
							placeholder='Search E-mail address'
							name='email'
							onChange={handleStrInput}
							InputProps={{
								startAdornment: (
									<InputAdornment position='start'>
										<HiOutlineSearch />
									</InputAdornment>
								),
							}}
							style={{
								marginBottom: "50px",
							}}
						/>
						<StyledSelect
							fullWidth
							variant='standard'
							size='small'
							name='active'
							value={searchParams.active ? searchParams.active : "All"}
							onChange={handleFilterSelect}
							style={{
								marginBottom: "50px",
							}}>
							<MenuItem value={"all"}>All</MenuItem>
							<MenuItem value={"true"}>Active</MenuItem>
							<MenuItem value={"false"}>Disabled</MenuItem>
						</StyledSelect>
						<StyledTextField
							fullWidth
							variant='standard'
							size='small'
							placeholder='30D volume (>)'
							name='volume'
							value={searchParams.volume}
							onChange={handleNumInput}
							InputProps={{
								startAdornment: (
									<InputAdornment position='start'>
										<HiOutlineSearch />
									</InputAdornment>
								),
							}}
							style={{
								marginBottom: "50px",
							}}
						/>
					</Box>
				</Box>
				<StyledTable>
					<StyledTableHead>
						<TableRow>
							<TableCell align='left'>
								<Typography fontFamily={"Poppins"} fontWeight={"bold"}>
									Name
								</Typography>
							</TableCell>
							<TableCell align='left'>
								<Typography fontFamily={"Poppins"} fontWeight={"bold"}>
									Country
								</Typography>
							</TableCell>
							<TableCell align='left'>
								<Typography fontFamily={"Poppins"} fontWeight={"bold"}>
									Active
								</Typography>
							</TableCell>
							<TableCell align='left'>
								<Typography fontFamily={"Poppins"} fontWeight={"bold"}>
									Balance (EUR)
								</Typography>
							</TableCell>
							<TableCell align='left'>
								<Typography fontFamily={"Poppins"} fontWeight={"bold"}>
									30d Volume
								</Typography>
							</TableCell>
							<TableCell align='left'></TableCell>
						</TableRow>
					</StyledTableHead>
					{isUsersLoading ? (
						<div>loading</div>
					) : (
						<TableBody>
							{users &&
								users?.data.length &&
								users?.data.map((user) => (
									<TableRow key={user.id}>
										<TableCell align='left'>
											<StyledPersonalInfo>
												<Typography fontFamily={"Poppins"} fontWeight={"bold"} fontSize={"16px"}>
													{user.name}
												</Typography>
												<Typography fontFamily={"Poppins"} fontWeight={400} fontSize={"14px"}>
													{user.email}
												</Typography>
											</StyledPersonalInfo>
										</TableCell>
										<TableCell align='left'>
											<Typography fontFamily={"Poppins"} fontWeight={500}>
												{user.country}
											</Typography>
										</TableCell>
										<TableCell align='left'>
											<Box display={"flex"} gap={"4px"} alignItems={"center"}>
												<span
													style={{
														display: "inline-flex",
														marginRight: "10px",
														width: "10px",
														height: "10px",
														borderRadius: "50%",
														background: user.is_active ? "#31C48D" : "#DD0000",
													}}></span>
												<Typography fontFamily={"Poppins"} fontWeight={500}>
													{user.is_active ? "Active" : "Disabled"}
												</Typography>
											</Box>
										</TableCell>
										<TableCell align='left'>
											<Typography fontFamily={"Poppins"} fontWeight={500}>
												{user.balance
													? user.balance.toLocaleString("en-US", {
															style: "currency",
															currency: "EUR",
													  })
													: "€0.00"}
											</Typography>
										</TableCell>
										<TableCell align='left'>
											<Typography fontFamily={"Poppins"} fontWeight={500}>
												{user.volume
													? user.volume.toLocaleString("en-US", {
															style: "currency",
															currency: "EUR",
													  })
													: "€0.00"}
											</Typography>
										</TableCell>
										<TableCell align='left'>
											<StyledButton
												variant='contained'
												onClick={() => navigate(`/admin/users/${user.id}`, { state: { ...user } })}>
												Edit user
											</StyledButton>
										</TableCell>
									</TableRow>
								))}
						</TableBody>
					)}
				</StyledTable>
				<Box display={"flex"} gap={"35px"} bgcolor={"#fff"} p={"20px"}>
					<Box display={"flex"}>
						<Button
							disabled={searchParams.page === 1}
							style={{ maxWidth: "15px" }}
							onClick={() =>
								setSearchParams({
									...searchParams,
									page: searchParams.page - 1,
								})
							}>
							<ArrowBackIosNewIcon style={{ color: "#000" }} />
						</Button>
						<Button
							disabled={users?.next_page === null}
							style={{ maxWidth: "15px" }}
							onClick={() =>
								setSearchParams({
									...searchParams,
									page: searchParams.page + 1,
								})
							}>
							<ArrowForwardIosIcon style={{ color: "#000" }} />
						</Button>
					</Box>
					<Box display={"flex"} alignItems={"center"}>
						<Typography textAlign={"center"} fontFamily={"Poppins"} display={"flex"} gap={"4px"}>
							Showing{" "}
							<Typography fontWeight={"bold"}>
								{users?.previous_page ? users?.previous_page + 1 : 1}
							</Typography>{" "}
							of <Typography fontWeight={"bold"}>{users?.pages}</Typography>
						</Typography>
					</Box>
				</Box>
			</StyledFilterWrapper>
		</Box>
	);
};

export default Users;
