import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { storageService } from "../../../services/storage.service";

export interface ICurrencyResponse {
    id: number;
    createdAt: string,
    updatedAt: string,
    baseCurrency: string,
    quoteCurrency: string,
    baseMinSize: string,
    baseMaxSize: string,
    baseScale: number,
    quoteMinSize: string,
    quoteMaxSize: string,
    quoteScale: number,
    orderOffset: number,
    active: boolean,
    markup: string,
    markdown: string
}

export const currencyApi = createApi({
    reducerPath: 'currencyApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_BASE_URL}/api/admin`,
        prepareHeaders: (headers) => {
            headers.set('Authorization', `Bearer ${storageService.getAccessToken}`)
            return headers
        }
    }),
    endpoints: (builder) => ({
        getCurrency: builder.query<ICurrencyResponse[], unknown>({
            query: () => ({
                url: '/currency',
                method: 'GET'
            })
        })
    })
})

export const {useGetCurrencyQuery} = currencyApi;