import {
  FC
} from 'react'
import {
  Box,
  Paper,
  Typography,
  TextField,
  Button
} from '@mui/material'
import {
  useForm,
  Controller,
  SubmitHandler
} from 'react-hook-form'
import { useAuthMutation } from '../../store/slices/profile/profile.api'

type FormData = {
  email: string,
  password: string
}

const AuthPage: FC = () => {
  const {
    handleSubmit,
    control,
    formState: {errors}
  } = useForm<FormData>()

  const [
    getAuthToken,
    {isError,
    error,
    isLoading}
  ] = useAuthMutation()

  const onAuth: SubmitHandler<FormData> = (formData) => {
    console.log(formData)
    getAuthToken(formData)
  }

  return (
    <Box
      display='flex'
      justifyContent='center'
      alignItems='center'
      height='100%'>
        <Paper
          elevation={1}
          style={{
            padding: '20px 25px 35px',
            width: '400px'
          }}>
          <form onSubmit={handleSubmit(onAuth)}>
            <Typography
              fontWeight={600}
              fontSize={30}
              lineHeight={'45px'}
              textAlign={'center'}
              marginBottom={'40px'}>
              Log In
            </Typography>
            <Controller
              name='email'
              control={control}
              defaultValue=''
              rules={{
                required: 'Email is required',
                pattern: {
                  value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: 'Email is not valid'
                }
              }}
              render={({field: {ref, onChange, value, ...field}, formState}) => (
                <TextField
                  {...field}
                  inputRef={ref}
                  onChange={onChange}
                  value={value}
                  variant='standard'
                  placeholder='E-mail *'
                  fullWidth
                  error={Boolean(errors.email)}
                  helperText={Boolean(errors.email) && errors.email?.message}
                  disabled={isLoading}
                  sx={{
                    marginBottom: '20px'
                  }}/>
              )}/>
            
            <Controller
              name='password'
              control={control}
              defaultValue=''
              rules={{
                required: 'Password is required'
              }}
              render={({field: {ref, onChange, value, ...field}, formState}) => (
                <TextField
                  {...field}
                  inputRef={ref}
                  onChange={onChange}
                  value={value}
                  variant='standard'
                  placeholder='Password *'
                  type={'password'}
                  fullWidth
                  error={Boolean(errors.password)}
                  helperText={Boolean(errors.password) && errors.password?.message}
                  disabled={isLoading}
                  sx={{
                    marginBottom: '30px'
                  }}/>
              )}/>
              {
                isError &&
                <div style={{
                  color: '#d32f2f',
                  marginBottom: '30px'
                }}>{(error as any).data?.detail}</div>
              }
            <Button
              variant='contained'
              fullWidth
              type='submit'
              disabled={isLoading}
              sx={{
                backgroundColor: '#0157FF',
                fontWeight: 600,
                textTransform: 'none',
                borderRadius: '12px'
              }}>
                Login
            </Button>
          </form>
        </Paper>
    </Box>
  )
}

export default AuthPage