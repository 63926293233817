import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { storageService } from "../../../services/storage.service";

interface INewsResponse {
    data: INews[],
    previous_page: number | null;
    next_page: number | null;
    total: number;
    pages: number;
}

export interface INews {
    show_for: string;
    id: number;
    created_at: string;
    title: string;
    text: string;
    updated_at: string | null;
}

interface INewsEditReq{
    news_id: number;
    title?: string;
    text?: string;
    show_for?: string;
}

export interface INewsPublishReq{
    title: string;
    text: string;
    show_for: string;
}

export const newsApi = createApi({
    reducerPath: 'newsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_BASE_URL}/api/admin/news`,
        prepareHeaders: (headers) => {
            headers.set('Authorization', `Bearer ${storageService.getAccessToken}`)
            return headers
        }
    }),
    endpoints: (builder) => ({
        getNews: builder.query<INewsResponse, null>({
            query: () => ({
                url: '',
                method: 'GET'
            })
        }),
        editNews: builder.mutation<boolean, INewsEditReq>({
            query: ({news_id, ...body}) => ({
                url: `/${news_id}`,
                method: 'POST',
                body: {...body}
            })
        }),
        deleteNews: builder.mutation<INews, number>({
            query: (params) => ({
                url: `/${params}`,
                method: 'DELETE'
            })
        }),
        publishNews: builder.mutation<INews, INewsPublishReq>({
            query: (req) => ({
                url: '',
                method: 'POST',
                body: {...req}
            })
        })
    })

});

export const {
    useGetNewsQuery, 
    useLazyGetNewsQuery, 
    useEditNewsMutation, 
    useDeleteNewsMutation,
    usePublishNewsMutation
} = newsApi;