import React, { useMemo, useState, useEffect } from 'react';
import { 
    Box, 
    Button, 
    Typography,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from "@mui/material";
import {
    StyledInput,
    StyledSelect,
} from "../Users/styled.users";
import { SVGs } from '../../assets/icons/images';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useGetUserQuery, useGetBalanceQuery, useChangePasswordMutation, IChangePassword, IIdEditUser, useEditUserMutation } from '../../store/slices/user/user.api';
import type { User as UserType } from '../../types/users.types';
import { useSnackbar, VariantType } from 'notistack';

const User = () => {
    const location = useLocation();
    const userState = location.state as UserType;

    const [showPasswords, setShowPasswords] = useState(false);
    
    const {
        isLoading,
        data: user
    } = useGetUserQuery(userState.id.toString())
    
    const [userChange, setUserChange] = useState<IIdEditUser>({
        user_id: userState.id,
        confirmation: {
            id_confirmed: user?.account.id_confirmed ? true : false,
            selfie_confirmed: user?.account.selfie_confirmed ? true : false,
            email_confirmed: user?.account.email_confirmed ? true : false
        }
    });
    const {
        isLoading: isLoadingWallet,
        data: wallet
    } = useGetBalanceQuery(userState.id.toString())
    
    const [passwordState, setPasswordState] = useState<IChangePassword>({
        user_id: userState.id,
        password: '',
        confirm_password: ''
    });

    const [change_password, {data, isLoading: loading, error}] = useChangePasswordMutation();
    const [editUser, {data: isEdited, isLoading: isEditLoading, error: isEditError}] = useEditUserMutation();

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if(data){
            enqueueSnackbar('Password changed!', { key: 'success'});
        }
        else if (error) enqueueSnackbar(`Could not change password. Maybe they are not equal`, { key: 'warning'});
    }, [data, error]);

    useEffect(() => {
        if(isEdited){
            enqueueSnackbar('User is edited!', { key: 'success'});
        }
        else if(isEditError) enqueueSnackbar(`Something wrong!`, { key: 'warning'});
    }, [isEdited]);

    const regisDate = useMemo(() => {
        if(user){
            const regisDate = new Date(user.general.registration_date)
            return `${regisDate.getDate()}.${regisDate.getMonth() + 1 < 10 ? '0' + (regisDate.getMonth() + 1) : regisDate.getMonth() + 1}.${regisDate.getFullYear()} ${regisDate.getHours() < 10 ? '0' + regisDate.getHours() : regisDate.getHours()}:${regisDate.getMinutes() < 10 ? '0' + regisDate.getMinutes() : regisDate.getMinutes()}`
        }
        return ''
    }, [user])

    const lastLoginTime = useMemo(() => {
        if(user && user.general.last_signup){
            const loginTime = new Date(user.general.last_signup)
            return `${loginTime.getDate() < 10 ? '0' + loginTime.getDate() : loginTime.getDate()}.${loginTime.getMonth() + 1 < 10 ? '0' + (loginTime.getMonth() + 1) : loginTime.getMonth() + 1}.${loginTime.getFullYear()} ${loginTime.getHours() < 10 ? '0' + loginTime.getHours() : loginTime.getHours()}:${loginTime.getMinutes() < 10 ? '0' + loginTime.getMinutes() : loginTime.getMinutes()}`
        }
        return '';
    }, [user]);

    return <Box width={"78%"}>
        <Box width={"100%"} bgcolor={"#fff"} p={"51px 42px 51px 111px"} borderRadius={"10px"}>
            <Box display={"flex"} gap={"50px"}>
                <Box>
                    <img src={SVGs.user}/>
                </Box>
                <Box
                    display={"flex"}
                    flexDirection={"column"}
                    gap={"12px"}
                >
                    <Box display={"flex"} gap={"35px"}>
                        <Box>
                            <Typography
                                fontFamily={"Poppins"}
                                fontSize={"24px"}
                                fontWeight={"bold"}
                                color={"#111928"}
                            >
                                {user?.general.first_name + ' ' + user?.general.last_name}
                            </Typography>
                            <Typography
                                fontFamily={"Poppins"}
                                fontSize={"24px"}
                                fontWeight={"bold"}
                                color={"#4B5563"}
                            >
                                USER ID: {userState.id}
                            </Typography>
                        </Box>
                        <Box 
                            maxHeight={"21px"} 
                            display={"flex"} 
                            gap={"11px"} 
                            alignItems={"center"}
                            mt={"5px"}
                        >
                            <span style={{
                                display: 'inline-flex',
                                marginRight: '10px',
                                width: '10px',
                                height: '10px',
                                borderRadius: '50%',
                                background: user?.active ? '#31C48D' : '#DD0000'
                            }}></span>
                            <span>{ user?.active ? 'Active' : 'Inactive'}</span>
                            <Box>
                                <img src={SVGs.arrowDown} />
                            </Box>
                        </Box>
                    </Box>
                    <Box
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        gap={"4px"}
                        bgcolor={"#0157FF"}
                        borderRadius={"12px"}
                        maxWidth={"134px"}
                        minHeight={"32px"}  
                    >
                        <img src={SVGs.gear} />
                        <Typography
                            fontFamily={"Poppins"}
                            fontWeight={600}
                            fontSize={"12px"}
                            color={"#fff"}
                        >
                            Change name
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box mt={"40px"}>
                <Typography
                    fontFamily={"Poppins"}
                    fontSize={"20px"}
                    fontWeight={"bold"}
                >
                    General Information
                </Typography>
                <Box>
                    <Box display={"flex"} gap={"122px"}>
                        <Box minWidth={"377px"}>
                            <Typography
                                fontFamily={"Poppins"}
                                fontSize={"16px"}
                                fontWeight={500}
                            >
                                First Name
                            </Typography>
                            <StyledInput 
                                defaultValue={user?.general.first_name} 
                                style={{marginBottom: '55px'}}
                                onChange={(e) => setUserChange({
                                    ...userChange,
                                    body: {
                                        ...userChange.body,
                                        first_name: e.target.value
                                    }
                                })}
                            />
                        </Box>
                        <Box minWidth={"377px"}>
                            <Typography
                                fontFamily={"Poppins"}
                                fontSize={"16px"}
                                fontWeight={500}
                            >
                                Last name
                            </Typography>
                            <StyledInput 
                                defaultValue={user?.general.last_name} 
                                style={{marginBottom: '55px'}}
                                onChange={(e) => setUserChange({
                                    ...userChange,
                                    body: {
                                        ...userChange.body,
                                        last_name: e.target.value
                                    }
                                })} 
                            />
                        </Box>
                    </Box>
                    <Box display={"flex"} gap={"122px"}>
                        <Box minWidth={"377px"}>
                            <Typography
                                fontFamily={"Poppins"}
                                fontSize={"16px"}
                                fontWeight={500}
                            >
                                Country
                            </Typography>
                            <StyledInput value={user?.general.country}/>
                        </Box>
                        <Box minWidth={"377px"}>
                            <Typography
                                fontFamily={"Poppins"}
                                fontSize={"16px"}
                                fontWeight={500}
                            >
                                Phone Number 
                            </Typography>
                            <StyledInput value={user?.general.phone ? user?.general.phone : 'No number'} disabled style={{marginBottom: '55px'}}/>
                        </Box>
                    </Box>
                    <Box display={"flex"} gap={"122px"}>
                        <Box minWidth={"377px"}>
                            <Typography
                                fontFamily={"Poppins"}
                                fontSize={"16px"}
                                fontWeight={500}
                            >
                                Date of birth
                            </Typography>
                            <StyledInput value={"17.06.1993"} disabled style={{marginBottom: '55px'}}/>
                        </Box>
                        <Box minWidth={"377px"}>
                            <Typography
                                fontFamily={"Poppins"}
                                fontSize={"16px"}
                                fontWeight={500}
                            >
                                Email
                            </Typography>
                            <StyledInput 
                                defaultValue={user?.general.email}
                                style={{marginBottom: '55px'}}
                                onChange={(e) => setUserChange({
                                    ...userChange,
                                    body: {
                                        ...userChange.body,
                                        email: e.target.value
                                    }
                                })}
                            />
                        </Box>
                    </Box>
                    <Box display={"flex"} gap={"122px"}>
                        <Box minWidth={"377px"}>
                            <Link to='client-wallet' state={wallet}>
                                <Typography
                                    fontFamily={"Poppins"}
                                    fontSize={"16px"}
                                    fontWeight={500}
                                    color={"#00B7F1"}
                                >
                                    Balance (wallet) (€)
                                </Typography>
                            </Link>
                            <StyledInput value={`€ ${user?.general.balance_eur}`} disabled style={{marginBottom: '55px'}}/>
                        </Box>
                        <Box minWidth={"377px"}>
                            <Typography
                                fontFamily={"Poppins"}
                                fontSize={"16px"}
                                fontWeight={500}
                            >
                                Affiliate (1 or more users reffered)
                            </Typography>
                            <StyledInput value={user?.general.affiliate ? "Yes" : "No"} disabled style={{marginBottom: '55px'}}/>
                        </Box>
                    </Box>
                    <Box display={"flex"} gap={"122px"}>
                        <Box minWidth={"377px"}>
                            <Typography
                                fontFamily={"Poppins"}
                                fontSize={"16px"}
                                fontWeight={500}
                            >
                                Registration date
                            </Typography>
                            <StyledInput value={regisDate} disabled style={{marginBottom: '55px'}}/>
                        </Box>
                        <Box minWidth={"377px"}>
                            <Typography
                                fontFamily={"Poppins"}
                                fontSize={"16px"}
                                fontWeight={500}
                            >
                                Registration IP
                            </Typography>
                            <StyledInput value={user?.general.registration_ip ? user?.general.registration_ip : 'No registration IP'} disabled style={{marginBottom: '55px'}}/>
                        </Box>
                    </Box>
                    <Box display={"flex"} gap={"122px"}>
                        <Box minWidth={"377px"}>
                            <Typography
                                fontFamily={"Poppins"}
                                fontSize={"16px"}
                                fontWeight={500}
                            >
                                Last login time
                            </Typography>
                            <StyledInput value={lastLoginTime ? lastLoginTime : 'No Last Login time'} disabled style={{marginBottom: '55px'}}/>
                        </Box>
                        <Box minWidth={"377px"}>
                            <Typography
                                fontFamily={"Poppins"}
                                fontSize={"16px"}
                                fontWeight={500}
                            >
                                Last login IP
                            </Typography>
                            <StyledInput value={user?.general.last_login_ip ? user?.general.last_login_ip : 'No Last Login IP' } disabled style={{marginBottom: '55px'}}/>
                        </Box>
                    </Box>
                </Box>
                <Box 
                    maxWidth={"94px"}
                    minHeight={"42px"}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    borderRadius={"12px"}
                    bgcolor={"#0157FF"}
                    onClick={() => editUser({
                            user_id: userChange.user_id,
                            body: {...userChange.body}
                        })
                    }
                >
                    <Typography
                        color={"#fff"}
                        fontWeight={"bold"}
                    >
                        Update
                    </Typography>
                </Box>
            </Box>
        </Box>
        <Box width={"100%"} bgcolor={"#fff"} p={"51px 42px 51px 111px"} borderRadius={"10px"} mt={"16px"}>
            <Typography
                fontFamily={"Poppins"}
                fontWeight={"bold"}
                fontSize={"20px"}
            >
                Password information
            </Typography>
            <Box mt={"15px"} >
                <Box display={"flex"} gap={"16px"}>
                    <Box minWidth={"377px"}>
                        <Typography
                            fontFamily={"Poppins"}
                            fontWeight={"bold"}
                            fontSize={"16px"}
                        >
                            New password
                        </Typography>
                        <StyledInput 
                            type={showPasswords ? "text" : "password"} 
                            placeholder="Enter new password" 
                            style={{marginBottom: '25px'}}
                            onChange={(e) => setPasswordState({
                                ...passwordState,
                                password: e.target.value
                            })}
                        />
                    </Box>
                    <Box minWidth={"377px"}>
                        <Typography
                            fontFamily={"Poppins"}
                            fontWeight={"bold"}
                            fontSize={"16px"}
                        >
                            Confirm new password
                        </Typography>
                        <StyledInput 
                            type={showPasswords ? "text" : "password"} 
                            placeholder="Confirm new password" 
                            style={{marginBottom: '25px'}}
                            onChange={(e) => setPasswordState({
                                ...passwordState,
                                confirm_password: e.target.value
                            })}
                        />
                    </Box>
                </Box>
                <Button style={{marginBottom: '15px'}} onClick={() => setShowPasswords(!showPasswords)}>
                    <Typography 
                        fontFamily={"Poppins"}
                        fontSize={"16px"}
                        color={"#157ao"}
                    >
                        { !showPasswords ? "Show passwords" : "Hide passwords"}
                    </Typography>
                </Button>
            </Box>
            <Box 
                maxWidth={"94px"}
                minHeight={"42px"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                borderRadius={"12px"}
                bgcolor={"#0157FF"}
                onClick={() => change_password({...passwordState})}
            >
                <Typography
                    color={"#fff"}
                    fontWeight={"bold"}
                >
                    Update
                </Typography>
            </Box>
        </Box>
        <Box width={"94%"} bgcolor={"#fff"} mt={"20px"} p={"36px 120px"}>
            <Typography 
                fontWeight={"bold"}
                fontSize={"20px"}
                fontFamily={"Poppins"}
                color={"#111928"}
                ml={"10%"}
            >Account information</Typography>
            
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Typography 
                                fontWeight={600} 
                                fontSize={"12px"} 
                                fontFamily={"Poppins"}
                                color={"#6B7280"}
                            >
                                Input
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography 
                                fontWeight={600} 
                                fontSize={"12px"} 
                                fontFamily={"Poppins"}
                                color={"#6B7280"}
                            >
                                Data
                            </Typography>
                        </TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow sx={{backgroundColor: '#FFF', borderRadius: '10px'}}>
                        <TableCell>
                            <Typography 
                                fontWeight={"bold"}
                                fontSize={"14px"}
                                fontFamily={"Poppins"}
                            >Email confirmed?</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography
                                fontWeight={400}
                                fontSize={"14px"}
                                fontFamily={"Poppins"}
                            >{userChange.confirmation?.email_confirmed ? 'Yes' : 'No'}</Typography>
                        </TableCell>
                        <TableCell>
                            <Button
                                onClick={() => setUserChange({
                                    ...userChange,
                                    confirmation: {
                                        ...userChange.confirmation,
                                        email_confirmed: !userChange.confirmation?.email_confirmed
                                    }
                                })}
                            >
                                <Typography
                                    fontSize={"14px"} 
                                    fontWeight={600}
                                    color={"#0157FF"}
                                >
                                    Edit
                                </Typography>
                            </Button>
                        </TableCell>
                    </TableRow>
                    <TableRow sx={{backgroundColor: '#FFF', borderRadius: '10px'}}>
                        <TableCell>
                            <Typography 
                                fontWeight={"bold"}
                                fontSize={"14px"}
                                fontFamily={"Poppins"}
                            >ID confirmed?</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography
                                fontWeight={400}
                                fontSize={"14px"}
                                fontFamily={"Poppins"}
                            >{userChange.confirmation?.id_confirmed ? 'Yes' : 'No'}</Typography>
                        </TableCell>
                        <TableCell>
                            <Button
                                onClick={() => setUserChange({
                                    ...userChange,
                                    confirmation: {
                                        ...userChange.confirmation,
                                        id_confirmed: !userChange.confirmation?.id_confirmed
                                    }
                                })}
                            >
                                <Typography
                                    fontSize={"14px"} 
                                    fontWeight={600}
                                    color={"#0157FF"}
                                >
                                    Edit
                                </Typography>
                            </Button>
                        </TableCell>
                    </TableRow>
                    <TableRow sx={{backgroundColor: '#FFF', borderRadius: '10px'}}>
                        <TableCell>
                            <Typography 
                                fontWeight={"bold"}
                                fontSize={"14px"}
                                fontFamily={"Poppins"}
                            >Face scan complete?</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography
                                fontWeight={400}
                                fontSize={"14px"}
                                fontFamily={"Poppins"}
                            >{userChange.confirmation?.selfie_confirmed ? 'Yes' : 'No'}</Typography>
                        </TableCell>
                        <TableCell>
                            <Button
                                onClick={() => setUserChange({
                                    ...userChange,
                                    confirmation: {
                                        ...userChange.confirmation,
                                        selfie_confirmed: !userChange.confirmation?.selfie_confirmed
                                    }
                                })}
                            >
                                <Typography
                                    fontSize={"14px"} 
                                    fontWeight={600}
                                    color={"#0157FF"}
                                >
                                    Edit
                                </Typography>
                            </Button>
                        </TableCell>
                    </TableRow>
                    <TableRow sx={{backgroundColor: '#FFF', borderRadius: '10px'}}>
                        <TableCell>
                            <Typography 
                                fontWeight={"bold"}
                                fontSize={"14px"}
                                fontFamily={"Poppins"}
                            >30D volume</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography
                                fontWeight={400}
                                fontSize={"14px"}
                                fontFamily={"Poppins"}
                            >€ {user?.account.volume}</Typography>
                        </TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    <TableRow sx={{backgroundColor: '#FFF', borderRadius: '10px'}}>
                        <TableCell>
                            <Typography 
                                fontWeight={"bold"}
                                fontSize={"14px"}
                                fontFamily={"Poppins"}
                            >Total trading fee’s</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography
                                fontWeight={400}
                                fontSize={"14px"}
                                fontFamily={"Poppins"}
                            >€ {user?.account.trading_fee}</Typography>
                        </TableCell>
                        <TableCell>
                        </TableCell>
                    </TableRow>
                    <TableRow sx={{backgroundColor: '#FFF', borderRadius: '10px'}}>
                        <TableCell>
                            <Typography 
                                fontWeight={"bold"}
                                fontSize={"14px"}
                                fontFamily={"Poppins"}
                            >Total spread fee’s</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography
                                fontWeight={400}
                                fontSize={"14px"}
                                fontFamily={"Poppins"}
                            >€ {user?.account.spread_fee}</Typography>
                        </TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            <Box 
                maxWidth={"94px"}
                minHeight={"42px"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                borderRadius={"12px"}
                bgcolor={"#0157FF"}
                mt={"25px"}
                onClick={() => editUser({
                    user_id: userChange.user_id,
                    confirmation: {...userChange.confirmation}
                })}
            >
                <Typography
                    color={"#fff"}
                    fontWeight={"bold"}
                >
                    Update
                </Typography>
            </Box>
        </Box>
    </Box>
}

export default User;