import React, {useState, useEffect} from "react";
import { 
    Box, 
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    LinearProgress,
    MenuItem,
    Select,
    SelectChangeEvent
} from "@mui/material";
import ReactCountryFlag from "react-country-flag";
import { StyledInput, StyledSelect } from "./Users/styled.users";
import { 
    useGetMarketsQuery, 
    useGetRecentQuery, 
    useGetSummaryQuery, 
    useGetCountriesSignUpQuery, 
} from "../store/slices/dashboard/dashboard.api";
import { Line, Column } from '@ant-design/plots';
import { ReactWorldCountriesMap } from "react-world-countries-map"
import { IRevenueResponse, useGetRevenueQuery } from "../store/slices/commissions/revenue.api";
import { months } from "./Commisions";

const Dashboard = () => {

    const [period, setPeriod] = useState('hours24'); 
    const [totalRevenue, setTotalRevenue] = useState(0);
    const [revenueState, setRevenue] = useState<IRevenueResponse[]>([]);
    const {
        isLoading,
        data: recent
    } = useGetRecentQuery(period);

    const {
        data: revenue
    } = useGetRevenueQuery('month');

    const {
        isLoading: loadingSummary,
        data: summary
    } = useGetSummaryQuery(null);

    const {
        isLoading: loadingMarket,
        data: market
    } = useGetMarketsQuery(null);

    const {
        isLoading: isCountryLoading,
        data: countrySignUp
    } = useGetCountriesSignUpQuery(null);

    const countryData = countrySignUp ? countrySignUp.map((country) => {
        return {
            country: country.code,
            value: country.count
        }
    }) : [];

    useEffect(() => {
        if(revenue){
            setRevenue([
                ...revenue.map((rev) => {
                    const splitted = rev.date.split('-');
                    const date = `${splitted[2]} ${months[parseInt(splitted[1]) - 1]}`
                    return {
                        market_income: rev.market_income,
                        market_income_eur: rev.market_income_eur,
                        market_base_fee: rev.market_base_fee,
                        date: date
                    }
                })
            ]);
            setTotalRevenue(() => revenue.reduce((prev, cur) => prev + cur.market_income_eur, 0))
        }
    }, [revenue]);

    return <Box width={"100%"} display={"flex"} flexDirection={"column"} gap={"15px"}>
        <Box 
            p={"23px 38px"}
            bgcolor={"#fff"}
            borderRadius={'10px'}
        >
            <Box
                display={"flex"}
                justifyContent={"space-between"}
            >
                <Box>
                    <Typography
                        fontFamily={"Poppins"}
                        fontSize={"20px"}
                        fontWeight={600}
                    >
                        Commissions
                    </Typography>
                </Box>
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    gap={'15px'}
                >
                    <Typography
                        fontFamily={"Poppins"}
                        fontSize={"18px"}
                        fontWeight={600}
                        color={'#111928'}
                    >
                        Total revenue today (€)
                    </Typography>
                    <StyledInput
                        value={`€ ${totalRevenue}`}
                    />
                </Box>
            </Box>
            <Box
                mt={"45px"}
            >
                <Column
                    data={(revenueState as any[])}
                    xField={'date'}
                    yField={'market_income_eur'}
                    label={
                        {
                            position: 'middle',
                            layout: [
                                {
                                    type: 'interval-adjust-position',
                                },
                                {
                                    type: 'interval-hide-overlap',
                                },
                                {
                                    type: 'adjust-color',
                                },
                            ]
                        }
                    }
                />
            </Box>
        </Box>
        <Box display={"flex"} gap={"15px"}>
            <Box width={"20%"}
                minHeight={"523px"} 
                p={"35px 40px"}
                sx={{ bgcolor: '#fff' }}
            >
                <Box 
                    display={"flex"} 
                    justifyContent={"space-between"}
                >
                    <Typography 
                        fontFamily={"Poppins"}
                        fontWeight={600}
                        fontSize={"20px"}
                        color={"#000"}
                    >
                        Recent
                    </Typography>
                    <StyledSelect
                        onChange={(e) => setPeriod((e.target.value as string))}
                        style={{maxHeight: '30px'}}
                        value={period}
                    >
                        <MenuItem
                            value={"hours24"}
                            selected={period === "hours24"}
                        >
                            24 h
                        </MenuItem>
                        <MenuItem
                            value={"week"}
                            selected={period === "week"}
                        >
                            1 week
                        </MenuItem>
                        <MenuItem
                            value={"month1"}
                            selected={period === "month1"}
                        >
                            1 month
                        </MenuItem>
                        <MenuItem
                            value={"month3"}
                            selected={period === "month3"}
                        >
                            3 months
                        </MenuItem>
                    </StyledSelect>
                </Box>
                <Box
                    display={"flex"}
                    flexDirection={"column"}
                    gap={"25px"}
                    mt={"40px"}
                >
                    <Box 
                        display={"flex"}
                        flexDirection={"column"}
                        gap={"10px"}
                    >
                        <Typography
                            fontFamily={"Poppins"}
                            fontSize={"14px"}
                            color={"#90949C"}
                            fontWeight={400}
                        >
                            Registrations
                        </Typography>
                        <Typography
                            fontFamily={"Poppins"}
                            fontWeight={600}
                        >
                            {recent?.registration}
                        </Typography>
                    </Box>
                    <Box 
                        display={"flex"}
                        flexDirection={"column"}
                        gap={"10px"}
                    >
                        <Typography
                            fontFamily={"Poppins"}
                            fontSize={"14px"}
                            color={"#90949C"}
                            fontWeight={400}
                        >
                            Deposits/registrations</Typography>
                        <Typography
                            fontFamily={"Poppins"}
                            fontWeight={600}
                        >
                            {recent?.percent}
                        </Typography>
                    </Box>
                    <Box 
                        display={"flex"}
                        flexDirection={"column"}
                        gap={"10px"}
                    >
                        <Typography
                            fontFamily={"Poppins"}
                            fontSize={"14px"}
                            color={"#90949C"}
                            fontWeight={400}
                        >
                            Trading commissions</Typography>
                        <Typography
                            fontFamily={"Poppins"}
                            fontWeight={600}
                        >
                            €{recent?.trading}
                        </Typography>
                    </Box>
                    <Box 
                        display={"flex"}
                        flexDirection={"column"}
                        gap={"10px"}
                    >
                        <Typography
                            fontFamily={"Poppins"}
                            fontSize={"14px"}
                            color={"#90949C"}
                            fontWeight={400}
                        >
                            Spread fee’s</Typography>
                        <Typography
                            fontFamily={"Poppins"}
                            fontWeight={600}
                        >
                            €{recent?.spread}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box width={"30%"} 
                minHeight={"523px"} 
                p={"35px 40px"}
                sx={{ bgcolor: '#fff' }}
            >
                <Box>
                    <Typography
                        fontFamily={"Poppins"}
                        fontWeight={600}
                        fontSize={"20px"}
                        color={"#000"}
                    >
                        Bitocol summary
                    </Typography>
                </Box>
                <Box
                    display={"flex"}
                    flexDirection={"column"}
                    gap={"25px"}
                    mt={"40px"}
                >
                    <Box
                        display={"flex"}
                        flexDirection={"column"}
                        gap={"10px"}
                    >
                        <Typography
                            fontFamily={"Poppins"}
                            fontSize={"14px"}
                            color={"#90949C"}
                            fontWeight={400}
                        >
                            Balance all wallets (BTC)
                        </Typography>
                        <Typography
                            fontFamily={"Poppins"}
                            fontWeight={600}
                        >
                            {summary?.balance_btc} BTC
                        </Typography>
                    </Box>
                    <Box
                        display={"flex"}
                        flexDirection={"column"}
                        gap={"10px"}
                    >
                        <Typography
                            fontFamily={"Poppins"}
                            fontSize={"14px"}
                            color={"#90949C"}
                            fontWeight={400}
                        >
                            Balance all wallets (€)
                        </Typography>
                        <Typography
                            fontFamily={"Poppins"}
                            fontWeight={600}
                        >
                            € {summary?.balance_eur}
                        </Typography>
                    </Box>
                    <Box
                        display={"flex"}
                        flexDirection={"column"}
                        gap={"10px"}
                    >
                        <Typography
                            fontFamily={"Poppins"}
                            fontSize={"14px"}
                            color={"#90949C"}
                            fontWeight={400}
                        >
                            Number of registrations
                        </Typography>
                        <Typography
                            fontFamily={"Poppins"}
                            fontWeight={600}
                        >
                            {summary?.users}
                        </Typography>
                    </Box>
                    <Box
                        display={"flex"}
                        flexDirection={"column"}
                        gap={"10px"}
                    >
                        <Typography
                            fontFamily={"Poppins"}
                            fontSize={"14px"}
                            color={"#90949C"}
                            fontWeight={400}
                        >
                            No of registrations (with trading vol)
                        </Typography>
                        <Typography
                            fontFamily={"Poppins"}
                            fontWeight={600}
                        >
                            {summary?.users_vol.count}
                        </Typography>
                    </Box>
                    <Box                     
                        display={"flex"}
                        flexDirection={"column"}
                        gap={"10px"}
                    >
                        <Typography
                            fontFamily={"Poppins"}
                            fontSize={"14px"}
                            color={"#90949C"}
                            fontWeight={400}
                        >
                            Average trading volume p/user p/m
                        </Typography>
                        <Typography
                            fontFamily={"Poppins"}
                            fontWeight={600}
                        >
                            € {summary?.avg_vol}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box width={"40%"} 
                minHeight={"523px"} 
                p={"35px 40px"}
                sx={{ bgcolor: '#fff' }}
            >
                <Box>
                    <Typography fontFamily={"Poppins"}
                        fontWeight={600}
                        fontSize={"20px"}
                        color={"#000"}
                    >
                        Top markets(24h)
                    </Typography>
                </Box>
                <Box overflow={"scroll"} maxHeight={"500px"}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>
                                    <Typography
                                        fontFamily={'Poppins'} 
                                        fontWeight={600} 
                                        fontSize={'14px'}
                                    >
                                        Pair
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        fontFamily={'Poppins'} 
                                        fontWeight={600} 
                                        fontSize={'14px'}
                                    >
                                        Volume
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        fontFamily={'Poppins'} 
                                        fontWeight={600} 
                                        fontSize={'14px'}
                                    > 
                                        Volume % 
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {market?.order?.map((item, index) => 
                            
                                <TableRow
                                    key={item.pairId}
                                >
                                    <TableCell>

                                        <Typography
                                            fontFamily={'Poppins'} 
                                            fontWeight={600} 
                                            fontSize={'14px'}
                                        > 
                                            {item.pairId}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography
                                            fontFamily={'Poppins'} 
                                            fontWeight={400} 
                                            fontSize={'14px'}
                                        > 
                                        {item.coin}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        
                                        <Typography
                                            fontFamily={'Poppins'} 
                                            fontWeight={400} 
                                            fontSize={'14px'}
                                        > 
                                            €{item.volume24HEUR}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography
                                            fontFamily={'Poppins'} 
                                            fontWeight={400} 
                                            fontSize={'14px'}
                                        >     
                                            {item.change24H}%
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </Box>
            </Box>
        </Box>
        <Box
            borderRadius={"10px"}
            sx={{backgroundColor: '#fff'}}
            p={"40px"}
        >
            <Box>
                <Typography
                    fontFamily={"Poppins"}
                    fontWeight={600}
                    fontSize={"20px"}
                >
                    Top 5 countries by signup
                </Typography>
            </Box> 
            <Box width={"100%"}>
                <ReactWorldCountriesMap
                    size={'lg'}
                    color='#0157FF'
                    data={countryData}
                />
            </Box>
            <Box mt={"40px"}
                display={"flex"}
                flexDirection={"column"}
                gap={"45px"}
            >
                {countrySignUp?.map((country, index) => {
                    return <Box 
                        display={"flex"} 
                        key={country.country_id}
                        gap={"40px"}
                        alignItems={"center"}
                    >
                        <Box
                            display={"flex"}
                            gap={"35px"}
                            minWidth={"20%"}
                        >
                            <Box>
                                <Typography
                                    fontFamily={"Poppins"}
                                    fontWeight={600}
                                    fontSize={"14px"}
                                >
                                    {index + 1}
                                </Typography>
                            </Box>
                            <Box>
                                <ReactCountryFlag countryCode={country.code} svg style={{
                                    width: '30px',
                                    height: '22px'
                                }}/>
                            </Box>
                            <Box>
                                <Typography
                                    fontFamily={"Poppins"}
                                    fontWeight={600}
                                    fontSize={"16px"}
                                >
                                    {country.name}
                                </Typography>
                            </Box>
                        </Box>
                        <Box width={"100%"} display="flex" flexDirection="column" gap="4px">
                            <LinearProgress
                                value={country.count}
                                variant={"determinate"}
                            />
                            <Typography
                                fontFamily={"Poppins"}
                                fontSize={"16px"}
                                fontWeight={600}
                            >
                                {country.count}
                            </Typography>
                        </Box>
                    </Box>
                })}
            </Box>
        </Box>
    </Box>
}

export default Dashboard;