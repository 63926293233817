import { createSlice } from "@reduxjs/toolkit";
import { profileState } from "./profile.state";
import { profileApi } from "./profile.api";
import { storageService } from "../../../services/storage.service";

export const profileSlice = createSlice({
  name: 'profile',
  initialState: profileState,
  reducers: {
    logout: () => profileState
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(profileApi.endpoints.auth.matchRejected, (state, action) => {
        console.log(action, 'reject')
      })
      .addMatcher(profileApi.endpoints.auth.matchFulfilled, (state, action) => {
        console.log(action, 'fulfilled')
        state.isAuthenticated = true
        storageService.setTokens = action.payload
      })
      .addMatcher(profileApi.endpoints.logout.matchFulfilled, (state, action) => {
        console.log(action, 'fulfilled')
        state.isAuthenticated = false
        storageService.removeTokens()
      })
  }
})