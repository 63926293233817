
export interface IUser {
    name: string
    country: string
    usersReferred: boolean
    balance: string | number
    monthVolume: string | number
    email: string
}

export interface IRefferedUser {
    name: string
    email: string
    registrationDate: string
    monthComm: string | number
    activeForCommission: boolean
}



export interface IState {
    users: IUser[]
    refferedUsers: IRefferedUser[]
}


export const userState: IState = {
    users: [
        {
            name: 'Max van der Knaap',
            balance: 100000,
            monthVolume: 10000000,
            country: 'KZ',
            usersReferred: false,
            email: 'maxvanderknaap@live.nl'
        },
        {
            name: 'Max van der Knaap',
            balance: 100000,
            monthVolume: 10000000,
            country: 'KZ',
            usersReferred: false,
            email: 'maxvanderknaap@live.nl'
        },
        {
            name: 'Max van der Knaap',
            balance: 100000,
            monthVolume: 10000000,
            country: 'KZ',
            usersReferred: false,
            email: 'maxvanderknaap@live.nl'
        },
        {
            name: 'Max van der Knaap',
            balance: 100000,
            monthVolume: 10000000,
            country: 'KZ',
            usersReferred: false,
            email: 'maxvanderknaap@live.nl'
        },
        {
            name: 'Max van der Knaap',
            balance: 100000,
            monthVolume: 10000000,
            country: 'KZ',
            usersReferred: false,
            email: 'maxvanderknaap@live.nl'
        },

    ],
    refferedUsers: [
        {
            name: 'Max van der Knaap',
            email: 'maxvanderknaap@live.nl',
            registrationDate: new Date().toString(),
            activeForCommission: false,
            monthComm: 3000
        },
        {
            name: 'Max van der Knaap',
            email: 'maxvanderknaap@live.nl',
            registrationDate: new Date().toString(),
            activeForCommission: false,
            monthComm: 3000
        },
        {
            name: 'Max van der Knaap',
            email: 'maxvanderknaap@live.nl',
            registrationDate: new Date().toString(),
            activeForCommission: false,
            monthComm: 3000
        },
        {
            name: 'Max van der Knaap',
            email: 'maxvanderknaap@live.nl',
            registrationDate: new Date().toString(),
            activeForCommission: false,
            monthComm: 3000
        },
        {
            name: 'Max van der Knaap',
            email: 'maxvanderknaap@live.nl',
            registrationDate: new Date().toString(),
            activeForCommission: false,
            monthComm: 3000
        }
    ]
}