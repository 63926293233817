import React, {useState, useEffect} from 'react';
import { 
    Box, 
    Typography,
    Input,
    TextareaAutosize,
    Button
} from "@mui/material";
import { usePublishNewsMutation } from '../../store/slices/news/news.api';
import { useNavigate } from 'react-router-dom';
import { useSnackbar, VariantType } from 'notistack';

const CreateNews = () => {

    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const [postNews, setPostNews] = useState<{
        title: string;
        text: string;
        show_for: string;
    } | null>(null);

    const [publish, {data: publishedData, isError}] = usePublishNewsMutation();

    useEffect(() => {
        if(publishedData){
            enqueueSnackbar('Published!', { key: 'success'});
            setTimeout(() => navigate(-1), 3000);
        }
        else if(isError){
            enqueueSnackbar(`Something wrong!`, { key: 'warning'});
        }
    }, [publishedData, isError]);

    const publishNews = () => {
        if (postNews){
            publish(postNews);
        }
    }

    return <Box
            display={"flex"}
            flexDirection={"column"}
            gap={"20px"}
            width={"100%"}
        >
        <Input type="text" 
            style={{
                backgroundColor: "#fff",
                border: "1px solid rgba(0, 0, 0, 0.6)",
                minHeight: "65px",
                padding: '5px'
            }}
            placeholder="New title"
            onChange={(e) => setPostNews({
                ...postNews!,
                title: e.target.value
            })}
        />
        <TextareaAutosize
            minRows={5}
            onChange={(e) => setPostNews({
                ...postNews!,
                text: e.target.value
            })}
        />
        <Input type="datetime-local"
            placeholder='Show until'
            style={{
                backgroundColor: "#fff",
                border: "1px solid rgba(0, 0, 0, 0.6)",
                minHeight: "65px",
                padding: '5px'
            }}
            onChange={(e) => {
                const isoDate = new Date(e.target.value);
                setPostNews({
                    ...postNews!,
                    show_for: isoDate.toISOString()
                })
            }}
        />
        <Box
            display={"flex"}
            justifyContent={"flex-end"}
        >
            <Box 
                display={"flex"}
                gap={"11px"}
            >
                <Box
                    fontFamily={"Poppins"}
                    minHeight={"38px"}
                    minWidth={'150px'}
                    borderRadius={"23px"}
                    bgcolor={"#0157ff"}
                    color={"#fff"}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    fontSize={"16px"}
                    onClick={publishNews}
                >
                    Publish
                </Box>
                <Box
                    fontFamily={"Poppins"}
                    minHeight={"38px"}
                    minWidth={"80px"}
                    borderRadius={"23px"}
                    bgcolor={"#232743"}
                    color={"#fff"}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    fontSize={"16px"}
                    onClick={() => navigate(-1)}
                >
                    Cancel
                </Box>
            </Box>
        </Box>
    </Box>
}

export default CreateNews;