import React, {useEffect} from 'react';
import { Provider } from 'react-redux';
import { AppRoutes } from './routes/Router';
import {store} from './store/store'
import { storageService } from './services/storage.service';

function App() {

  useEffect(() => {
    const intervalId = setInterval(() => {
      storageService.sendRefresh();
    }, 30000)

    return () => {
      clearInterval(intervalId)
    }
  }, []);
  return (
    <Provider store={store}>
      <AppRoutes />
    </Provider>
  )
}

export default App;
