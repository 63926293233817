import {
  styled,
  TextField,
  Select,
  Table,
  TableHead,
  Button
} from '@mui/material'

export const StyledFilterWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  padding: '50px 40px 0'
}))

export const StyledInput = styled('input')({
  display: 'block',
  width: '100%',
  outline: 'none',
  height: '45px',
  border: '1px solid #E5E7EB',
  borderRadius: '16px',
  background: '#fff',
  padding: '0 20px',
  fontSize: '16px',
  color: '#6B7280',
  boxSizing: 'border-box'
})

export const StyledTextField = styled(TextField)({
  '& .MuiInputAdornment-root': {
    color: '#000'
  }
})

export const StyledSelect = styled(Select)({

})

export const StyledTable = styled(Table)({
  background: '#fff'
})

export const StyledTableHead = styled(TableHead)({
  background: '#80848E',
  '& .MuiTableCell-root': {
    color: '#fff',
    lineHeight: '40px',
    fontWeight: 600,
    fontSize: '14px'
  }
})

export const StyledPersonalInfo = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  fontSize: '14px',
  '& span:first-of-type': {
    fontWeight: 600,
    textDecoration: 'underline'
  },
  '& span:last-of-type': {
    
  }
})

export const StyledButton = styled(Button)({
  textTransform: 'none',
  fontWeight: 600,
  backgroundColor: '#0157FF',
  borderRadius: '12px',
  '&:hover': {
    backgroundColor: '#064acf'
  }
})