import React, {useState, useEffect, useMemo} from "react";
import { 
    Box, 
    Button, 
    Typography, 
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    InputAdornment,
} from "@mui/material";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { SVGs } from "../../assets/icons/images";
import { StyledInput, StyledTextField } from "../Users/styled.users";
import { HiOutlineSearch } from "react-icons/hi";
import { useLocation, useNavigate } from "react-router-dom";
import { User } from "../../types/users.types";
import { useGetReferralsQuery, useGetAffOfUserQuery, useEditAffIdMutation, IEditAffId } from "../../store/slices/affliates/affiliates.api";
import { useSnackbar, VariantType } from 'notistack';

const UserAffiliate = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const state = location.state as User;

    const [queryUserName, setQueryUserName] = useState<string | null>(null);
    const [page, setPage] = useState(1);
    const {
        data: refs, 
        isLoading
    } = useGetReferralsQuery({
        id_: state.id,
        page: page,
        q: queryUserName
    });

    const {
        data: userAff,
        isLoading: isLoadingUserAff
    } = useGetAffOfUserQuery(state.id.toString())

    const [editAffId, {data: isEdited, error}] = useEditAffIdMutation();

    const [editAffState, setEditAffState] = useState<IEditAffId>({
        id: state.id,
        email: state.email
    });

    const [isEdit, setIsEdit] = useState<{
        first_name: boolean;
        last_name: boolean;
        email: boolean;
        commission: boolean;
    }>({
        first_name: false,
        last_name: false,
        email: false,
        commission: false
    });

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if(userAff){
            setEditAffState({
                ...editAffState,
                first_name: userAff.first_name,
                last_name: userAff.last_name,
                commission: userAff.commission_percent
            })
        }
    }, [userAff]);

    useEffect(() => {
        if(isEdited){
            enqueueSnackbar("Aff changed!", { key: 'success' })
        }
        else if (error) enqueueSnackbar(`Something wrong!`, { key: 'warning'});
    }, [isEdited]);

    return <Box width={"100%"}>
    <Box
        width={"100%"}
        bgcolor={"#fff"}
    >   
        <Box p={"22px 33px"}
            width={"139px"} 
        >
            <Box  
                display={"flex"} 
                height={"35px"}
                alignItems={"center"}
                border={"1.5px solid #00B7F1"}
                justifyContent={"center"}
                gap={"10px"}   
                onClick={() => window.location.href='/affiliates'}
            >
                <img src={SVGs.backIcon}/>
                <Typography
                    fontFamily={"Poppins"}
                    fontSize={"16px"}
                    color={"#00b7f1"}
                >
                    Back
                </Typography>
            
            </Box>
        </Box>
        <Box display={"flex"}
            gap={"50px"}
            p={"22px 33px"}
        >
            <Box>
                <img src={SVGs.user} style={{marginTop: "10px"}} alt=""/>
            </Box>
            <Box width={"80%"}>
                <Box>
                    <Typography
                        fontFamily={"Poppins"}
                        fontWeight={"bold"}
                        fontSize={"25px"}
                        color={"#111928"}
                    >
                        Affiliate Summary
                    </Typography>
                    <Typography
                        fontWeight={"bold"}
                        fontSize={"15px"}
                        color={"#111928"}
                    >
                        {userAff?.first_name + ' ' + userAff?.last_name}
                    </Typography>
                    <Typography color={"#4B5563"}>
                        AFFILIATE ID: {userAff?.affiliate_id}
                    </Typography>
                </Box>
                <Box 
                    display={"flex"} 
                    gap={"70px"}
                    mt={"30px"}
                >
                    <Box 
                        width={"40%"}
                    >
                        <Typography
                            fontFamily={"Poppins"}
                            fontWeight={"bold"}
                            fontSize={"16px"}
                            color={"#111928"}
                            mb={"25px"}
                        >
                            Total users referred
                        </Typography>
                        <StyledInput
                            value={userAff?.count_referred}
                            disabled
                        />
                    </Box>
                    <Box 
                        width={"40%"}
                    >
                        <Typography
                            fontFamily={"Poppins"}
                            fontWeight={"bold"}
                            fontSize={"16px"}
                            color={"#111928"}
                            mb={"25px"}
                        >
                            Total commission earned (€)
                        </Typography>
                        <StyledInput
                            value={`${userAff?.total_earned ? '€' + userAff?.total_earned : 'No earned commission'}`}
                            disabled
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
        <Box width={"100%"}>
            <Typography 
                fontWeight={"bold"}
                fontSize={"20px"}
                fontFamily={"Poppins"}
                color={"#111928"}
                ml={"10%"}
            >Account information</Typography>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Typography 
                                fontWeight={600} 
                                fontSize={"12px"} 
                                fontFamily={"Poppins"}
                                color={"#6B7280"}
                            >
                                Input
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography 
                                fontWeight={600} 
                                fontSize={"12px"} 
                                fontFamily={"Poppins"}
                                color={"#6B7280"}
                            >
                                Data
                            </Typography>
                        </TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow sx={{backgroundColor: '#FFF', borderRadius: '10px'}}>
                        <TableCell>
                            <Typography 
                                fontWeight={"bold"}
                                fontSize={"14px"}
                                fontFamily={"Poppins"}
                            >First Name</Typography>
                        </TableCell>
                        <TableCell>
                            {!isEdit.first_name ? <Typography
                                    fontWeight={400}
                                    fontSize={"14px"}
                                    fontFamily={"Poppins"}
                                >{editAffState.first_name}</Typography> 
                                : <StyledInput
                                    disabled={!isEdit.first_name}
                                    style={{
                                        maxWidth: '300px'
                                    }}
                                    defaultValue={editAffState.first_name}
                                    onChange={(e) => setEditAffState({
                                        ...editAffState,
                                        first_name: e.target.value
                                    })}
                                />
                            }
                        </TableCell>
                        <TableCell>
                            <Button
                                onClick={() => setIsEdit({
                                    ...isEdit,
                                    first_name: !isEdit.first_name
                                })}
                            >
                                <Typography
                                    fontSize={"14px"} 
                                    fontWeight={600}
                                    color={"#0157FF"}
                                >
                                    { isEdit.first_name ? 'Apply' : 'Edit' }
                                </Typography>
                            </Button>
                        </TableCell>
                    </TableRow>
                    <TableRow sx={{backgroundColor: '#FFF', borderRadius: '10px'}}>
                        <TableCell>
                            <Typography 
                                fontWeight={"bold"}
                                fontSize={"14px"}
                                fontFamily={"Poppins"}
                            >Last Name</Typography>
                        </TableCell>
                        <TableCell>
                            {!isEdit.last_name ? <Typography
                                fontWeight={400}
                                fontSize={"14px"}
                                fontFamily={"Poppins"}
                            >{editAffState.last_name}</Typography>
                                : <StyledInput
                                    disabled={!isEdit.last_name}
                                    style={{
                                        maxWidth: '300px'
                                    }}
                                    defaultValue={editAffState.last_name}
                                    onChange={(e) => setEditAffState({
                                        ...editAffState,
                                        last_name: e.target.value
                                    })}
                                />
                            }
                        </TableCell>
                        <TableCell>
                            <Button
                                onClick={() => setIsEdit({
                                    ...isEdit,
                                    last_name: !isEdit.last_name
                                })}
                            >
                                <Typography
                                    fontSize={"14px"} 
                                    fontWeight={600}
                                    color={"#0157FF"}
                                >
                                    { isEdit.last_name ? 'Apply' : 'Edit' }
                                </Typography>
                            </Button>
                        </TableCell>
                    </TableRow>
                    <TableRow sx={{backgroundColor: '#FFF', borderRadius: '10px'}}>
                        <TableCell>
                            <Typography 
                                fontWeight={"bold"}
                                fontSize={"14px"}
                                fontFamily={"Poppins"}
                            >Affiliate ID</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography
                                fontWeight={400}
                                fontSize={"14px"}
                                fontFamily={"Poppins"}
                            >{userAff?.affiliate_id}</Typography>
                        </TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    <TableRow sx={{backgroundColor: '#FFF', borderRadius: '10px'}}>
                        <TableCell>
                            <Typography 
                                fontWeight={"bold"}
                                fontSize={"14px"}
                                fontFamily={"Poppins"}
                            >Email</Typography>
                        </TableCell>
                        <TableCell>
                            {!isEdit.email ? <Typography
                                    fontWeight={400}
                                    fontSize={"14px"}
                                    fontFamily={"Poppins"}
                                >{editAffState.email}</Typography> 
                                : <StyledInput
                                    disabled={!isEdit.email}
                                    style={{
                                        maxWidth: '300px'
                                    }}
                                    defaultValue={editAffState.email}
                                    onChange={(e) => setEditAffState({
                                        ...editAffState,
                                        email: e.target.value
                                    })}
                                />
                            }
                        </TableCell>
                        <TableCell>
                            <Button
                                onClick={() => setIsEdit({
                                    ...isEdit,
                                    email: !isEdit.email
                                })}
                            >
                                <Typography
                                    fontSize={"14px"} 
                                    fontWeight={600}
                                    color={"#0157FF"}
                                >
                                    { isEdit.email ? 'Apply' : 'Edit' }
                                </Typography>
                            </Button>
                        </TableCell>
                    </TableRow>
                    <TableRow sx={{backgroundColor: '#FFF', borderRadius: '10px'}}>
                        <TableCell>
                            <Typography 
                                fontWeight={"bold"}
                                fontSize={"14px"}
                                fontFamily={"Poppins"}
                            >Commission %</Typography>
                        </TableCell>
                        <TableCell>
                            {!isEdit.commission ? 
                                <Typography
                                    fontWeight={400}
                                    fontSize={"14px"}
                                    fontFamily={"Poppins"}
                                >{editAffState.commission}%</Typography>
                                : <StyledInput
                                    type="number"
                                    style={{
                                        maxWidth: '300px'
                                    }}
                                    disabled={!isEdit.commission}
                                    defaultValue={editAffState.commission}
                                    onChange={(e) => setEditAffState({
                                        ...editAffState,
                                        commission: parseInt(e.target.value)
                                    })}
                                />
                            }
                        </TableCell>
                        <TableCell>
                            <Button
                                onClick={() => setIsEdit({
                                    ...isEdit,
                                    commission: !isEdit.commission
                                })}
                            >
                                <Typography
                                    fontSize={"14px"} 
                                    fontWeight={600}
                                    color={"#0157FF"}
                                >
                                    { isEdit.commission ? 'Apply' : 'Edit' }
                                </Typography>
                            </Button>
                        </TableCell>
                    </TableRow>
                    <TableRow sx={{backgroundColor: '#FFF', borderRadius: '10px'}}>
                        <TableCell>
                            <Typography 
                                fontWeight={"bold"}
                                fontSize={"14px"}
                                fontFamily={"Poppins"}
                            >Total users reffered</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography
                                fontWeight={400}
                                fontSize={"14px"}
                                fontFamily={"Poppins"}
                            >
                                {userAff?.count_referred}
                            </Typography>
                        </TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    <TableRow sx={{backgroundColor: '#FFF', borderRadius: '10px'}}>
                        <TableCell>
                            <Typography 
                                fontWeight={"bold"}
                                fontSize={"14px"}
                                fontFamily={"Poppins"}
                            >Total commission earned</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography
                                fontWeight={400}
                                fontSize={"14px"}
                                fontFamily={"Poppins"}
                            >{userAff?.total_earned ? '€' + userAff?.total_earned : 'No earned commission'}</Typography>
                        </TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            <Box 
                maxWidth={"94px"}
                minHeight={"42px"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                borderRadius={"12px"}
                bgcolor={"#0157FF"}
                mt={"35px"}
                ml={"20px"}
                onClick={() => editAffId({
                    ...editAffState
                })}
            >
                <Typography
                    color={"#fff"}
                    fontWeight={"bold"}
                >
                    Update
                </Typography>
            </Box>
        </Box>
        <Box width={"100%"}
            borderRadius={"10px"}
            bgcolor={"#fff"}
            // mt={"60px"}
        >   
            <Box p={"52px 55px"}>
                <Typography
                    fontFamily={'Poppins'}
                    fontStyle={"normal"}
                    fontWeight={700}
                    fontSize={"25px"}
                    display={"flex"}
                    alignItems={"center"}
                    gap={"5px"}
                >
                    Referred users
                    <Typography
                        fontWeight={500}
                        fontSize={"25px"}
                    >
                        (AFFILIATE ID: {state.id})
                    </Typography>
                </Typography>
            </Box>
            <Table>
                <TableHead>
                    <TableRow sx={{backgroundColor: "#80848E"}}>
                        <TableCell>
                            <Typography 
                                fontFamily={"Poppins"} 
                                color={"#fff"}
                                fontSize={"14px"}
                                fontWeight={600}    
                            >Name</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography 
                                fontFamily={"Poppins"} 
                                color={"#fff"}
                                fontSize={"14px"}
                                fontWeight={600}    
                            >Registration Date</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography 
                                fontFamily={"Poppins"} 
                                color={"#fff"}
                                fontSize={"14px"}
                                fontWeight={600}    
                            >30d comm. for aff. (€)</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography 
                                fontFamily={"Poppins"} 
                                color={"#fff"}
                                fontSize={"14px"}
                                fontWeight={600}    
                            >Active for commissions?</Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <StyledTextField
                    variant="standard"
                    size="small"
                    placeholder="Search name / email address"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <HiOutlineSearch/>
                            </InputAdornment>
                        )
                    }}
                    onChange={(e) => setQueryUserName(e.target.value)}
                    style={{
                        minWidth: '240px',
                        margin: '15px'
                    }}
                />
                <TableBody>
                    {
                        refs?.data.map((ref) => {
                            const date = new Date(ref.created_at);
                            const [day, month, year] = [date.getDate(), date.getMonth(), date.getFullYear()];
                            const [hour, minute, seconds] =  [date.getHours(), date.getMinutes(), date.getSeconds()];
                            const regisDate = `${day < 10 ? '0' + day : day}.${month + 1 < 10 ? '0' + (month + 1): month + 1}.${year} ${hour < 10 ? '0' + hour : hour}:${minute < 10 ? '0' + minute : minute}:${seconds < 10 ? '0' + seconds : seconds }`
                            return <TableRow key={ref.id}>
                                <TableCell>
                                    <Typography
                                        fontSize={"14px"}
                                        fontWeight={600}
                                        fontFamily={"Poppins"}
                                    >
                                        {ref.name}
                                    </Typography>
                                    <Typography
                                        fontSize={"14px"}
                                        fontWeight={500}
                                        fontFamily={"Poppins"}
                                    >
                                        {ref.email}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        fontFamily={"Poppins"}
                                        fontWeight={500}
                                        fontSize={"14px"}
                                    >
                                        {regisDate}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        fontFamily={"Poppins"}
                                        fontSize={"14px"}
                                        fontWeight={500}
                                    >
                                        {!ref.income_30d ? 'No income' : `€${ref.income_30d}`}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                                        <Box display={"flex"} alignItems={"center"}>
                                            <span style={{
                                                display: 'inline-flex',
                                                marginRight: '10px',
                                                width: '10px',
                                                height: '10px',
                                                borderRadius: '50%',
                                                background: ref.is_active ? '#31C48D' : '#DD0000'
                                            }}></span>
                                            <Typography 
                                                fontFamily={"Poppins"}
                                                fontWeight={500}
                                            >
                                                {ref.is_active ? 'Active' : 'Not active'} 
                                            </Typography>  
                                        </Box>
                                        <Button>
                                            <Typography
                                                fontSize={"14px"} 
                                                fontWeight={500}
                                                color={"#0157FF"}
                                            >
                                                Edit
                                            </Typography>
                                        </Button>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        })
                    }
                </TableBody>
            </Table>
            <Box 
                display={"flex"} 
                gap={"35px"} 
                bgcolor={"#fff"}
                p={"20px"}
            >
                <Box display={"flex"}>
                    <Button disabled={refs?.previous_page === null} style={{maxWidth: '15px'}}
                        onClick={() => setPage(page - 1)}
                    >
                        <ArrowBackIosNewIcon style={{color: '#000'}}/>
                    </Button>
                    <Button disabled={refs?.next_page === null} style={{maxWidth: '15px'}}
                        onClick={() => setPage(page + 1)}
                    >
                        <ArrowForwardIosIcon style={{color: '#000'}}/>
                    </Button>
                </Box>
                <Box display={"flex"} alignItems={"center"}>
                    <Typography textAlign={"center"} fontFamily={"Poppins"} display={"flex"} gap={"4px"}>
                        Showing <Typography fontWeight={"bold"}>{refs?.previous_page ? refs?.previous_page + 1 : 1}</Typography> of <Typography fontWeight={"bold"}>{refs?.pages}</Typography>
                    </Typography>
                </Box>
            </Box>
        </Box>
    </Box>
</Box>
};

export default UserAffiliate;