import { FC, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useTypedSelector } from "../../hooks/redux";
import { storageService } from "../../services/storage.service";

const AuthLayout: FC = () => {
	const { isAuthenticated } = useTypedSelector((store) => store.profileSlice);
	const nav = useNavigate();

	useEffect(() => {
		if (storageService.isHaveTokens) {
			nav("/admin");
		}
	}, [isAuthenticated, nav]);

	return <Outlet />;
};

export default AuthLayout;
