import React from "react";
import { 
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Button
} from "@mui/material";
import { useGetCurrencyQuery } from "../store/slices/currency/currency,api";

const Currency = () => {

    const {
        data: currency
    } = useGetCurrencyQuery(null);

    return <Box width={"100%"}
        maxHeight={"850px"}
        overflow={"scroll"}
        bgcolor={"#fff"}
        borderRadius={"10px"}
    >
        <Table>
            <TableHead
                style={{
                    position: 'sticky',
                    top: 0
                }}
            >
                <TableRow
                    sx={{backgroundColor: '#80848E', borderRadius: '10px'}}
                >
                    <TableCell>
                        <Typography
                            fontFamily={"Poppins"}
                            fontWeight={600}
                            color={"#fff"}
                            fontSize={"16px"}
                        >
                            Currency pair
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography
                            fontFamily={"Poppins"}
                            fontWeight={600}
                            color={"#fff"}
                            fontSize={"16px"}
                        >
                            Maker fee (T1)
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography
                            fontFamily={"Poppins"}
                            fontWeight={600}
                            color={"#fff"}
                            fontSize={"16px"}
                        >
                            Taker fee (T1)
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography
                            fontFamily={"Poppins"}
                            fontWeight={600}
                            color={"#fff"}
                            fontSize={"16px"}
                        >
                            Mark-up (SE)
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography
                            fontFamily={"Poppins"}
                            fontWeight={600}
                            color={"#fff"}
                            fontSize={"16px"}
                        >
                            Mark-down (SE)
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography
                            fontFamily={"Poppins"}
                            fontWeight={600}
                            color={"#fff"}
                            fontSize={"16px"}
                        >
                            Active
                        </Typography>
                    </TableCell>
                    <TableCell></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {currency?.map((item) => 
                    <TableRow key={item.id}>
                        <TableCell>
                            <Typography
                                fontFamily={"Poppins"}
                                fontSize={"16px"}
                                fontWeight={500}
                            >
                                {item.baseCurrency}/{item.quoteCurrency}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography
                                fontFamily={"Poppins"}
                                fontSize={"16px"}
                                fontWeight={500}
                            >
                                0.35%
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography
                                fontFamily={"Poppins"}
                                fontSize={"16px"}
                                fontWeight={500}
                            >
                                0.35%
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography
                                fontFamily={"Poppins"}
                                fontSize={"16px"}
                                fontWeight={500}
                            >
                                {item.markup}%
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography
                                fontFamily={"Poppins"}
                                fontSize={"16px"}
                                fontWeight={500}
                            >
                                {item.markdown}%
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Box display={"flex"} alignItems={"center"}>
                                <span style={{
                                    display: 'inline-flex',
                                    marginRight: '10px',
                                    width: '10px',
                                    height: '10px',
                                    borderRadius: '50%',
                                    background: item.active ? '#31C48D' : '#DD0000'
                                }}></span>
                                <Typography 
                                    fontFamily={"Poppins"}
                                    fontWeight={500}
                                >
                                    {item.active ? 'Active' : 'Not active'} 
                                </Typography>  
                            </Box>
                        </TableCell>
                        <TableCell>
                            <Button>
                                <Typography
                                    fontSize={"14px"} 
                                    fontWeight={500}
                                    color={"#0157FF"}
                                >
                                    Edit
                                </Typography>
                            </Button>
                        </TableCell>
                    </TableRow>
                )}
            </TableBody>
        </Table>
    </Box>
}

export default Currency;