import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { CountriesResponse } from '../../../types/countries.types'

export const countriesApi = createApi({
  reducerPath: 'countriesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_BASE_URL}/api/v1/`,
 }),
 endpoints: (builder) => ({
  getCountries: builder.query<CountriesResponse, any>({
    query: () => ({
      url: 'country',
      method: 'GET'
    })
  })
 })
})

export const {
  useGetCountriesQuery
} = countriesApi