import React, {useState, useEffect, useMemo} from "react";
import { 
    Box, 
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    Typography,
} from "@mui/material";
import { Line, Column } from '@ant-design/plots';
import { IDay, IRevenueResponse, useGetCommissionQuery, useGetRevenueQuery } from "../store/slices/commissions/revenue.api";

interface ICommission {
    id: string;
    currencyPair: string;
    euroVolume: number;
    percentageVolume: number;
    tradingFee: number;
    spreadFee: number;
}

export const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
]

const Commisions = () => {

    const [period, setPeriod] = useState<'week' | 'month' | 'year'>('year');
    const {data: revenue} = useGetRevenueQuery(period);
    const {data: deposit} = useGetCommissionQuery(null);

    const commissions: ICommission[] = [
        {
            id: '1',
            currencyPair: "BTC/USDC",
            euroVolume: 235255.25,
            percentageVolume: 21.45,
            tradingFee: 453.12,
            spreadFee: 256.86
        },
        {
            id: '2',
            currencyPair: "ETH/USDC",
            euroVolume: 235255.25,
            percentageVolume: 21.45,
            tradingFee: 453.12,
            spreadFee: 256.86
        },
        {
            id: '3',
            currencyPair: "BNB/USDC",
            euroVolume: 235255.25,
            percentageVolume: 21.45,
            tradingFee: 453.12,
            spreadFee: 256.86
        },
        {
            id: '4',
            currencyPair: "XRP/USDC",
            euroVolume: 235255.25,
            percentageVolume: 21.45,
            tradingFee: 453.12,
            spreadFee: 256.86
        },
        {
            id: '5',
            currencyPair: "ADA/USDC",
            euroVolume: 235255.25,
            percentageVolume: 21.45,
            tradingFee: 453.12,
            spreadFee: 256.86
        }
    ];

    const [filteredRevenue, setFilteredRev] = useState<IRevenueResponse[]>([])
    const [filteredDeposit, setFilteredDeposits] = useState<IDay[]>([]);

    useEffect(() => {
        let rev: IRevenueResponse[] = [];
        let dep: IDay[] = [];
        if(revenue && revenue.length > 0){
            rev = revenue.map((rev) => {
                const splitted = rev.date.split('-');
                const date = `${splitted[2]} ${months[parseInt(splitted[1]) - 1]}`
                return {
                    market_income: rev.market_income,
                    market_income_eur: rev.market_income_eur,
                    market_base_fee: rev.market_base_fee,
                    date: date
                }
            })
            setFilteredRev([...rev])
        }
        if(deposit && deposit.days.length > 0){
            dep = deposit.days.map((day) => {
                const splitted = day.date.split('-');
                const date = `${splitted[2]} ${months[parseInt(splitted[1]) - 1]}`
                return {
                    date: date,
                    sum: day.sum
                }
            })
            setFilteredDeposits([...dep])
        }
    }, [revenue, deposit]);

    const depositDetail = {
        total: (): number | null => {
            return filteredDeposit ? filteredDeposit.reduce((prev, cur) => prev + cur.sum, 0) : null
        },
        percent: deposit?.percent
    }

    return <Box 
            width={"100%"}
            display={"flex"}
            flexDirection={"column"}
            gap={"20px"}
        >
        <Box 
            width={"100%"} 
            bgcolor={"#fff"}
            p={"31px 27px 25px"}
            boxSizing={"border-box"}
            borderRadius={"10px"}
        >
            <Box 
                display={"flex"} 
                justifyContent={"space-between"}
            >
                <Typography
                    fontFamily={"Poppins"}
                    fontSize={"20px"}
                    fontWeight={700}
                    color={"#111928"}
                >
                    Revenue
                </Typography>
                <Box 
                    display={"flex"} 
                    borderRadius={"12px"} 
                    border={"1px solid #E5E7EB"}
                    minHeight={"40px"}
                >
                    <Box
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        bgcolor={period === 'year' ? '#1C64F2' : '#fff'}
                        color={period === 'year' ? '#fff' : '#111928'}
                        width={"75px"}
                        borderRadius={"12px 0px 0px 12px"}
                        onClick={() => setPeriod("year")}
                    >
                        <Typography
                            fontFamily={"Poppins"}
                            fontWeight={500}
                            fontSize={"16px"}
                        >
                            Year
                        </Typography>
                    </Box>
                    <Box
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        bgcolor={period === 'month' ? '#1C64F2' : '#fff'}
                        color={period === 'month' ? '#fff' : '#111928'}
                        width={"75px"}
                        onClick={() => setPeriod("month")}
                    >
                        <Typography
                            fontFamily={"Poppins"}
                            fontWeight={500}
                            fontSize={"16px"}
                        >
                            Month
                        </Typography>
                    </Box>
                    <Box
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        bgcolor={period === 'week' ? '#1C64F2' : '#fff'}
                        color={period === 'week' ? '#fff' : '#111928'}
                        width={"75px"}
                        borderRadius={"0px 12px 12px 0px"}
                        onClick={() => setPeriod("week")}
                    >
                        <Typography
                            fontFamily={"Poppins"}
                            fontWeight={500}
                            fontSize={"16px"}
                        >
                            Week
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box mt={"45px"}>
                <Line 
                    data={filteredRevenue ? (filteredRevenue as any[]) : []}
                    xField={'date'}
                    yField={'market_income_eur'}
                    smooth={true}
                    animation={
                        {
                            appear: {
                                animation: 'path-in',
                                duration: 3000
                            }
                        }
                    }
                    legend={{position: 'top'}}
                />
            </Box>
        </Box>
        <Box
            maxWidth={"530px"} 
            bgcolor={"#fff"}
            p={"31px 27px 25px"}
            boxSizing={"border-box"}
            borderRadius={"10px"}
        >
            <Box p={"25px 45px"}>
                <Typography 
                    color={"#111928"}
                    fontSize={"20px"}
                    fontWeight={700}
                    fontFamily={"Poppins"}
                >
                    Deposits (Fiat)
                </Typography>
                <Box display={"flex"} justifyContent={"space-between"}>
                    <Box>
                        <Typography
                            color={"#111928"}
                            fontSize={"30px"}
                            fontWeight={700}
                            fontFamily={"Poppins"}
                        >
                            €{depositDetail.total()}
                        </Typography>
                        <Typography
                            color={"#4b5563"}
                            fontSize={"16px"}
                            fontWeight={400}
                            fontFamily={"Poppins"}
                        >
                            This years's deposits
                        </Typography>
                    </Box>
                    <Box
                        display={"flex"} 
                        justifyContent={"center"}
                        alignItems={"center"}
                    >
                        <Typography
                            fontFamily={"Inter"}
                            color={ depositDetail.percent! >= 0 ? "#0E9F6E" : 'red'}
                            fontSize={"16px"}
                            fontWeight={700}
                        >
                            {depositDetail.percent}%
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Column
                data={filteredDeposit ? (filteredDeposit as any[]) : []}
                xField={'date'}
                yField={'sum'}
                xAxis={
                    {
                        label: {
                            autoHide: true,
                            autoRotate: true
                        }
                    }
                }
                minColumnWidth={20}
                maxColumnWidth={20}
                color={"#1A56DB"}
                animation={
                    {
                        appear: {
                            animation: 'path-in',
                            duration: 3000
                        }
                    }
                }
            />
        </Box>
        <Box 
            bgcolor={"#fff"}
            borderRadius={"10px"}
        >
            <Table>
                <TableHead sx={{backgroundColor:"#80848E"}}>
                    <TableRow>
                        <TableCell>
                            <Box display={"flex"} gap={"5px"}>
                                <Typography 
                                    fontWeight={600} 
                                    fontSize={"14px"} 
                                    fontFamily={"Poppins"}
                                    color={"#fff"}
                                >
                                    Currency pair
                                </Typography>
                            </Box>
                        </TableCell>
                        <TableCell>
                            <Box display={"flex"} gap={"5px"}>
                                <Typography 
                                    fontWeight={600} 
                                    fontSize={"14px"} 
                                    fontFamily={"Poppins"}
                                    color={"#fff"}
                                >
                                    Volume(€)
                                </Typography>
                            </Box>
                        </TableCell>
                        <TableCell>
                            <Box display={"flex"} gap={"5px"}>
                                <Typography 
                                    fontWeight={600} 
                                    fontSize={"14px"} 
                                    fontFamily={"Poppins"}
                                    color={"#fff"}
                                >
                                    Volume(%)
                                </Typography>
                            </Box>
                        </TableCell>
                        <TableCell>
                            <Box display={"flex"} gap={"5px"}>
                                <Typography 
                                    fontWeight={600} 
                                    fontSize={"14px"} 
                                    fontFamily={"Poppins"}
                                    color={"#fff"}
                                >
                                    Trading fee's
                                </Typography>
                            </Box>
                        </TableCell>
                        <TableCell>
                            <Box display={"flex"} gap={"5px"}>
                                <Typography 
                                    fontWeight={600} 
                                    fontSize={"14px"} 
                                    fontFamily={"Poppins"}
                                    color={"#fff"}
                                >
                                    Spread fee's
                                </Typography>
                            </Box>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {   
                        commissions.map((com) => (
                            <TableRow key={com.id}>
                                <TableCell>
                                    <Typography
                                        fontFamily={"Poppins"}
                                        fontSize={"14px"}
                                        fontWeight={400}
                                        color={"#000"}
                                    >
                                        {com.currencyPair}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        fontFamily={"Poppins"}
                                        fontSize={"14px"}
                                        fontWeight={400}
                                        color={"#000"}
                                    >
                                        €{com.euroVolume}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        fontFamily={"Poppins"}
                                        fontSize={"14px"}
                                        fontWeight={400}
                                        color={"#000"}
                                    >
                                        {com.percentageVolume}{' %'}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        fontFamily={"Poppins"}
                                        fontSize={"14px"}
                                        fontWeight={400}
                                        color={"#000"}
                                    >
                                        €{com.tradingFee}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        fontFamily={"Poppins"}
                                        fontSize={"14px"}
                                        fontWeight={400}
                                        color={"#000"}
                                    >
                                        €{com.spreadFee}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </Box>
    </Box>
}

export default Commisions;