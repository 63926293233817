import { FC, useEffect } from "react";
import Header from "../Header";
import NavBar from "../NavBar";
import { Box } from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";
import { useTypedSelector } from "../../hooks/redux";
import { storageService } from "../../services/storage.service";

const PrimaryLayout: FC = () => {
	const { isAuthenticated } = useTypedSelector((store) => store.profileSlice);
	const nav = useNavigate();

	useEffect(() => {
		if (!storageService.isHaveTokens) {
			nav("/admin/auth");
		}
	}, [isAuthenticated, nav]);

	return (
		<>
			<Header />
			<Box height={"87%"} display={"flex"} gap={"15px"} p={"6px 15px"}>
				<NavBar />
				<Outlet />
			</Box>
		</>
	);
};

export default PrimaryLayout;
