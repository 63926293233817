
export const SVGs = {
    dashboard: require('./dashboard.svg').default,
    affl: require('./affl.svg').default,
    currency: require('./currency.svg').default,
    users: require('./users.svg').default,
    user: require('./user.svg').default,
    backIcon: require('./back-icon.svg').default,
    commision: require('./commisions.svg').default,
    lightDash: require('./light_dash.svg').default,
    lightUsers: require('./light_users.svg').default,
    lightAffl: require('./light_affl.svg').default,
    lightCurrency: require('./light_currency.svg').default,
    lightCommissions: require('./light_commissions.svg').default,
    search: require('./search.svg').default,
    arrowDown: require('./arrow-down.svg').default,
    gear: require('./gear.svg').default
}