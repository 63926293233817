import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import type { UsersResponse } from '../../../types/users.types'
import { storageService } from '../../../services/storage.service'

export const usersApi = createApi({
  reducerPath: 'usersApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_BASE_URL}/api/`,
    prepareHeaders: (headers) => {
        headers.set('Authorization', `Bearer ${storageService.getAccessToken}`)
        return headers
    }
 }),
 endpoints: (builder) => ({
  getUsers: builder.query<UsersResponse, string>({
    query: (params) => ({
      url: `admin/users?${params}`,
      method: 'GET'
    })
  })
 })
})

export const {
  useGetUsersQuery
} = usersApi